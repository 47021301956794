import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Container, Table, ListGroup } from 'react-bootstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from '@mui/material/IconButton';
import Collapse from "@material-ui/core/Collapse";
import * as Utils from '../../utils/utils';
import ShowAlert from '../showalert/showalert';
import './validation.css';

const Validation = () => {
  const state = useSelector(state => state);
  const [runlistData, setRunlistData] = useState([]);
  const [h5PlotData, setH5PlotData] = useState([]);
  const [fwhmPlotData, setFwhmPlotData] = useState([]);
  const [runConfigData, setRunConfigData] = useState([]);
  const [errorVData, setErrorVData] = useState([]);
  const [rsqVData, setRsqVData] = useState([]);
  const [bSVData, setBSVData] = useState([]);
  const [clumpingVData, setClumpingVData] = useState([]);
  const [linearityVData, setLinearityVData] = useState([]);
  const [bFVData, setBFVData] = useState([]);
  const [berHmerVData, setBerHmerVData] = useState([]);
  const [berVData, setBerVData] = useState([]);
  const [rlqVData, setRlqVData] = useState([]);
  const [fMetricVData, setFMetricVData] = useState([]);
  const [qVData, setQVData] = useState([]);
  const [phasingVData, setPhasingVData] = useState([]);
  const [readLengthVData, setReadLengthVData] = useState([]);
  const [readFilteringVData, setReadFilteringVData] = useState([]);
  const [signalLinearityVData, setSignalLinearityVData] = useState([]);
  const [expandedIds, setExpandedIds] = React.useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]);

  const handleExpandClick = id => {
    setExpandedIds(expandedIds.includes(id) ? [...expandedIds.filter(item => item !== id)] : [...expandedIds, id]);
  }

  const updatingStringValues = (string) => {
    if (typeof string !== 'string' || string === null || string === undefined) {
      return string;
    }
    let value = string.split('');
    return value.length > 0 ? string : 'Empty String';
  };

  const updatingH5PlotStringValues = arr => {
    try {
      if(!arr) return null;

      let result = {};
      for(const i of arr){
        const [key, value] = i.split(':').map(part => part.trim());
        result[key] = value;
      }
      return result;
      
    } catch (error) {
      console.log(error)
    }
  };

const displayByCamera = (data) => {
  let cam1Data = [];
  let cam2Data = [];

  if (data.length === 1 && data[0].includes('Error')) {
    cam1Data.push(data[0]);
    cam2Data.push(data[0]);
  } else {
    data.forEach(item => {
      if (item.includes('cam1') || item.includes('camera_1')) {
        cam1Data.push(item);
      } else if (item.includes('cam2') || item.includes('camera_2')) {
        cam2Data.push(item);
      }
    });
  }

  return {
    cam1: cam1Data,
    cam2: cam2Data
  };
};

  useEffect(() => {
    setRunlistData([]);
    setH5PlotData([])
    setFwhmPlotData([]);
    setRunConfigData([]);
    setErrorVData([]);
    setRsqVData([]);
    setBSVData([]);
    setClumpingVData([]);
    setLinearityVData([]);
    setBFVData([]);
    setBerHmerVData([]);
    setBerVData([]);
    setRlqVData([]);
    setFMetricVData([]); 
    setQVData([]);
    setReadLengthVData([]);
    setReadFilteringVData([]);
    setSignalLinearityVData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.runId]);


useEffect(() => {
  try {
      if(state.validated_single_run?.length > 0){
        let copySingleRun = state.validated_single_run;
        let filteredData = copySingleRun.map(obj => {
          const filteredObj = {};
          for (const key in obj) {
            const value = obj[key];
            if (value === null || value === undefined || (typeof value === 'string' && value.includes('null')) || updatingStringValues(value) === 'Empty String') {
              filteredObj[key] = value;
            }
          }
          return filteredObj;
        });
        setRunlistData(filteredData[0]);
      }
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.validated_single_run])

useEffect(() => {
    try {
        if(state.validated_h5plot_data?.length > 0){
            let copyH5plot = state.validated_h5plot_data;
            let updateData = updatingH5PlotStringValues(copyH5plot);
            setH5PlotData(updateData);
        } else setH5PlotData([]);
    } catch (error) {
        console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.validated_h5plot_data])

useEffect(() => {
  try {
      if(state.fwhm_validated_data){
          let copyFwhmPlot = state.fwhm_validated_data;
          let updateData = displayByCamera(copyFwhmPlot);
          if(updateData.cam1?.length > 0 || updateData.cam2?.length > 0) setFwhmPlotData(updateData);
          else setFwhmPlotData(undefined);
      }
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.fwhm_validated_data])

useEffect(() => {
  try {
      if(state.run_configuration_validated_data?.length > 0){
          let copyRunConfig = state.run_configuration_validated_data;
          setRunConfigData(copyRunConfig);
      } else setRunConfigData([]);
  } catch (error) {
      console.log(error);
  }
}, [state.run_configuration_validated_data])

useEffect(() => {
  try {
      if(state.error_plots_validated){
          let copyErrorV = state.error_plots_validated;
          setErrorVData(copyErrorV);
      } else setErrorVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.error_plots_validated])

useEffect(() => {
  try {
      if(state.rsq_plots_validated?.length > 0){
          let copyRsqV = state.rsq_plots_validated;
          setRsqVData(copyRsqV);
      } else setRsqVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.rsq_plots_validated])

useEffect(() => {
  try {
      if(state.barcode_statistics_validated?.length > 0){
          let copyBSV = state.barcode_statistics_validated;
          setBSVData(copyBSV);
      } else setBSVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.barcode_statistics_validated])

useEffect(() => {
  try {
      if(state.clumpings_validated?.length > 0){
          let copyClumpingV = state.clumpings_validated;
          setClumpingVData(copyClumpingV);
      } else setClumpingVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.clumpings_validated])

useEffect(() => {
  try {
      if(state.linearity_validated?.length > 0){
          let copyLinearityV = state.linearity_validated;
          setLinearityVData(copyLinearityV);
      } else setLinearityVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.linearity_validated])

useEffect(() => {
  try {
      if(state.beads_filtered_validated?.length > 0){
          let copyBFV = state.beads_filtered_validated;
          setBFVData(copyBFV);
      } else setBFVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.beads_filtered_validated])

useEffect(() => {
  try {
      if(state.ber_hmer_validated?.length > 0){
          let copyBerHmerV = [...state.ber_hmer_validated];
          let updateBerHmer = displayByCamera(copyBerHmerV)
          setBerHmerVData(updateBerHmer);
      } else setBerHmerVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.ber_hmer_validated])

useEffect(() => {
  try {
      if(state.ber_validated?.length > 0){
          let copyBerV = [...state.ber_validated];
          let updateBer = displayByCamera(copyBerV)
          setBerVData(updateBer);
      } else setBerVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.ber_validated])

useEffect(() => {
  try {
    if (state.rlq_validated?.length > 0) {
      let copyRlqV = [...state.rlq_validated];
      setRlqVData(copyRlqV);
    } else {
      setRlqVData([]);
    }
  } catch (error) {
    console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.rlq_validated]);

useEffect(() => {
  try {
      if(state.fmetrics_validated?.length > 0){
          let copyFMetricV = state.fmetrics_validated;
          setFMetricVData(copyFMetricV);
      } else setFMetricVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.fmetrics_validated])

useEffect(() => {
  try {
      if(state.q_validated?.data?.length > 0){
          let copyQV = structuredClone(state.q_validated);
          setQVData([copyQV.data]);
      } else setQVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.q_validated])

useEffect(() => {
  try {
      if(state.phasing_validated?.length > 0){
          let copyQV = structuredClone(state.phasing_validated);
          setPhasingVData(copyQV);
      } else setPhasingVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.phasing_validated])

useEffect(() => {
  try {
      if(state.read_length_validated){
          let copyRLV = structuredClone(state.read_length_validated);
          setReadLengthVData(copyRLV);
      } else setReadLengthVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.read_length_validated])


useEffect(() => {
  try {
      if(state.reads_filtering_validated){
          let copyRFV = structuredClone(state.reads_filtering_validated);
          setReadFilteringVData(copyRFV);
      } else setReadFilteringVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.reads_filtering_validated])

useEffect(() => {
  try {
      if(state.signal_linearity_validated){
          let copySLV = structuredClone(state.signal_linearity_validated);
          setSignalLinearityVData(copySLV);
      } else setSignalLinearityVData([]);
  } catch (error) {
      console.log(error);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [state.signal_linearity_validated])

  return (
    <Container>
      <div id="validDivToPrint" style={{ marginBottom: 50}}>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(1)}
                            aria-expanded={expandedIds.includes(1)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Run List Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(1)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {runlistData !== undefined && 
                                      (
                                        <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                          <thead>
                                            <tr>
                                              <th>Property</th>
                                              <th>Value</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Object.keys(runlistData).map(key => (
                                              <tr key={key}>
                                                <td>{key}</td>
                                                <td>{runlistData[key] === null
                                                ? <span><strong style={{ color: 'red' }}>null</strong></span>
                                                : runlistData[key] === undefined
                                                ? <span><strong style={{ color: 'red' }}>undefined</strong></span>
                                                : runlistData[key] === runlistData['subRows']
                                                ? <span>Contains subRows</span>
                                                : runlistData[key] === runlistData['runstatus'] && typeof runlistData['runstatus'] !== 'string'
                                                ? <span>Contains Object</span>
                                                : updatingStringValues(runlistData[key].toString())}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </Table>
                                      )
                                    }
                                    {((runlistData === undefined || runlistData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(2)}
                            aria-expanded={expandedIds.includes(2)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Quality of Control Sample Plot Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(2)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {typeof h5PlotData === 'object' && Object.keys(h5PlotData).length !== 0 &&
                                      (
                                        <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                          <thead>
                                            <tr>
                                              <th>Property</th>
                                              <th>Value</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {Object.keys(h5PlotData)?.map(key => (
                                              <tr key={key}>
                                                <td>{key}</td>
                                                <td>{h5PlotData[key]}</td>
                                              </tr>
                                            ))}
                                        </tbody>
                                        </Table>
                                      ) 
                                    }
                                    {((h5PlotData === undefined || Object.keys(h5PlotData).length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(3)}
                            aria-expanded={expandedIds.includes(3)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="FWHM Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(3)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {fwhmPlotData && Object.keys(fwhmPlotData)?.length > 0 &&
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Camera</th>
                                            <th>Data</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.keys(fwhmPlotData).map(camera => (
                                            <tr key={camera}>
                                              <td>{camera}</td>
                                              <td>
                                                {fwhmPlotData[camera].map((item, index) => (
                                                  <div key={index}>{item}</div>
                                                ))}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                    {((fwhmPlotData === undefined || Object.keys(fwhmPlotData).length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(4)}
                            aria-expanded={expandedIds.includes(4)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Run Configuration Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(4)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                      {runConfigData && runConfigData.some(item => Object.values(item)[0] && Object.values(item)[0].length > 0) ? (
                                        <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                          <thead>
                                            <tr>
                                              <th>Type</th>
                                              <th>Camera 1</th>
                                              <th>Camera 2</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {runConfigData.map((item, index) => {
                                              const [key, value] = Object.entries(item)[0];
                                              if (Array.isArray(value) && value.length > 0) {
                                                const camera1Error = value.find(err => err.includes('camera_1') || err.includes('directory') || err.includes('table'));
                                                const camera2Error = value.find(err => err.includes('camera_2') || err.includes('directory') || err.includes('table'));
                                                return (
                                                  <tr key={index}>
                                                    <td>{key}</td>
                                                    <td>{camera1Error || ''}</td>
                                                    <td>{camera2Error || ''}</td>
                                                  </tr>
                                                );
                                              }
                                              return null;
                                            }).filter(Boolean)}
                                          </tbody>
                                        </Table>
                                      ) : null}

                                      {((runConfigData === undefined || runConfigData.every(item => !Object.values(item)[0] || Object.values(item)[0].length === 0)) && 
                                        !state.is_validated_data_loading) && (
                                        <Col lg={12}>
                                          <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                        </Col>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(5)}
                            aria-expanded={expandedIds.includes(5)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Error plots Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(5)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                  <div className='custom-scrollbar'>
                                    {errorVData && Object.values(errorVData).some(value => value && value.length > 0) ? (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Plot</th>
                                            <th>Data</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.entries(errorVData).map(([plot, data]) => (
                                            data && data.length > 0 && (
                                              <tr key={plot}>
                                                <td>{plot}</td>
                                                <td>
                                                  {data.map((item, index) => (
                                                    <div key={index}>{item}</div>
                                                  ))}
                                                </td>
                                              </tr>
                                            )
                                          ))}
                                        </tbody>
                                      </Table>
                                    ) : (!state.is_validated_data_loading && (
                                      <Col lg={12}>
                                        <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                      </Col>
                                    ))}
                                  </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(6)}
                            aria-expanded={expandedIds.includes(6)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Read Length plots Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(6)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                  <div className='custom-scrollbar'>
                                    {readLengthVData && Object.values(readLengthVData).some(value => value && value.length > 0) ? (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Plot</th>
                                            <th>Data</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.keys(readLengthVData).map(plot => (
                                            <tr key={plot}>
                                              <td>{plot}</td>
                                              <td>
                                                {readLengthVData[plot] && readLengthVData[plot].length > 0 && (
                                                  readLengthVData[plot].map((item, index) => (
                                                    <div key={index}>{item}</div>
                                                  ))
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    ) : (!state.is_validated_data_loading && (
                                      <Col lg={12}>
                                        <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                      </Col>
                                    ))}
                                  </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(7)}
                            aria-expanded={expandedIds.includes(7)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Median RSQ Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(7)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                <Col>
  <div className='custom-scrollbar'>
    {rsqVData?.length > 0 && (
      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <thead>
          <tr>
            <th>Error Message</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(rsqVData[0]) ? (
            rsqVData.map((item, index) => (
              <tr key={index}>
                <td>{item}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>{rsqVData[0]}</td>
            </tr>
          )}
        </tbody>
      </Table>
    )}
    {((rsqVData === undefined || rsqVData.length === 0) && !state.is_validated_data_loading) && (
      <Col lg={12}>
        <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
      </Col>
    )}
  </div>
</Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(8)}
                            aria-expanded={expandedIds.includes(8)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Barcode Statistics Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(8)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {bSVData?.length > 0 && 
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {bSVData?.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                     {((bSVData === undefined || bSVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(9)}
                            aria-expanded={expandedIds.includes(9)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Clumping Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(9)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {clumpingVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {clumpingVData?.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                     {((clumpingVData === undefined || clumpingVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(10)}
                            aria-expanded={expandedIds.includes(10)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Linearity Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(10)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {linearityVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {linearityVData.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                    {((linearityVData === undefined || linearityVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(11)}
                            aria-expanded={expandedIds.includes(11)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Beads Filter Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(11)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {bFVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                        {bFVData.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                    {((bFVData === undefined || bFVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(12)}
                            aria-expanded={expandedIds.includes(12)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Phasing Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(12)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {phasingVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {phasingVData?.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                     {((phasingVData === undefined || phasingVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(13)}
                            aria-expanded={expandedIds.includes(13)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Ber Hmer Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(13)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                      {berHmerVData && Object.keys(berHmerVData)?.length > 0 && (berHmerVData?.cam1?.length > 0 || berHmerVData?.cam2?.length > 0) && (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Camera</th>
                                            <th>Data</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.keys(berHmerVData).map(camera => (
                                            <tr key={camera}>
                                              <td>{camera}</td>
                                              <td>
                                              {berHmerVData[camera].length > 0 && (
                                                berHmerVData[camera].map((item, index) => (
                                                  <div key={index}>{item}</div>
                                                ))
                                              )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      ) 
                                    }
                                     {((berHmerVData === undefined || Object.keys(berHmerVData).length === 0 || (berHmerVData.cam1.length === 0 && berHmerVData.cam2.length === 0)) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(14)}
                            aria-expanded={expandedIds.includes(14)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Ber Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(14)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {berVData && Object.keys(berVData)?.length > 0 && (berVData?.cam1?.length > 0 || berVData?.cam2?.length > 0) && (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Camera</th>
                                            <th>Data</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.keys(berVData).map(camera => (
                                            <tr key={camera}>
                                              <td>{camera}</td>
                                              <td>
                                                {berVData[camera].length > 0 && (
                                                  berVData[camera].map((item, index) => (
                                                    <div key={index}>{item}</div>
                                                  ))
                                                )}
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                    )}
                                    {((berVData === undefined || Object.keys(berVData).length === 0 || (berVData.cam1.length === 0 && berVData.cam2.length === 0)) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(15)}
                            aria-expanded={expandedIds.includes(15)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="RLQ Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(15)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                  <div className='custom-scrollbar'>
                                    {rlqVData?.length > 0 && 
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {rlqVData.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                    {((rlqVData === undefined || rlqVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(16)}
                            aria-expanded={expandedIds.includes(16)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="F Metric Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(16)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                  <div className='custom-scrollbar'>
                                    {fMetricVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {fMetricVData?.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      ) 
                                    }
                                     {((fMetricVData === undefined || fMetricVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                  </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row> */}
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(17)}
                            aria-expanded={expandedIds.includes(17)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Q Table Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(17)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                  <div className='custom-scrollbar' >
                                    {qVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {qVData?.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      )
                                    }
                                    {((qVData === undefined || qVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(18)}
                            aria-expanded={expandedIds.includes(18)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Reads Filter Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(18)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {readFilteringVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {readFilteringVData?.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      ) 
                                    }
                                    {((readFilteringVData === undefined || readFilteringVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row>
          {/* <Row style={{ marginTop: 10 }}>
            <Col lg={12}>
              <Card sx={{ minWidth: 275, zIndex: 1, marginTop: 3 }}>
                <CardHeader
                    action={
                        <IconButton
                            onClick={() => handleExpandClick(19)}
                            aria-expanded={expandedIds.includes(19)}
                            aria-label="Expand/Collapse"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    }
                    title="Signal Linearity Data"
                    subheader={state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}
                />
                  <Collapse in={expandedIds.includes(19)} timeout="auto">
                      <CardContent>
                          <ListGroup variant="flush">
                              <ListGroup.Item>
                                <Row>
                                  <Col>
                                    <div className='custom-scrollbar'>
                                    {signalLinearityVData?.length > 0 &&
                                      (
                                      <Table striped bordered hover style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                        <thead>
                                          <tr>
                                            <th>Error Message</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {signalLinearityVData?.map((item, index) => (
                                            <tr key={index}>
                                              <td>{item}</td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </Table>
                                      ) 
                                    }
                                    {((signalLinearityVData === undefined || signalLinearityVData.length === 0) && !state.is_validated_data_loading) &&
                                            <Col lg={12}>
                                                <ShowAlert type={'success'} text={`No errors for ${state.runId !== undefined ? Utils.getRunIdWithoutCamera(state.runId) : state.runId}.`} />
                                            </Col>
                                        }
                                    </div>
                                  </Col>
                                </Row>
                              </ListGroup.Item>
                          </ListGroup>
                      </CardContent>
                  </Collapse>
              </Card>
            </Col>
          </Row> */}
      </div>
    </Container>
  )
}

export default Validation;