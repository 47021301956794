import React, { useEffect } from 'react';
import { Tab, Card, ListGroup, Row, Col, Nav, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ShowAlert from '../showalert/showalert';
import { setTemplateReportRenderData, getSupplementReportAsync, setSupplementReportRenderData, setActiveTemplateReportSubTabName, getLateFLowMetricsDataAsync, getToolNoisePlots, getNoiseAndContextDataAsync, } from "../actions/index";
import SinglePagePDFViewer from "./singlepage";
import LoadingSpinner from '../spinner/spinner';
import * as Utils from '../../utils/utils';
import Image from '../image/image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TableRowsIcon from '@mui/icons-material/TableRows';
import DownloadLink from '../downloadlink/downloadlink';
import Frame from '../frame/frame';

const TemplateReport = (props) => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [templateReportData, setTemplateReportData] = React.useState(undefined);
    const [supplementData, setSupplementData] = React.useState(undefined);
    const [lateFlowMetricsData, setLateFlowMetricsData] = React.useState(undefined);
    const [toolNoiseData, setToolNoiseData] = React.useState([]);
    const [toolNoiseTableData, setToolNoiseTableData] = React.useState(undefined);

    useEffect(() => {
        try {
            let exists = state.template_report.filter(f => f.runid === state.runId);
            if (exists.length > 0) {
                dispatch(setTemplateReportRenderData(exists[0].data, state.runId));
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.template_report]);

    useEffect(() => {
        try {
            let exists = state.template_report_render_data.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data) {
                setTemplateReportData(exists[0].data);
            }
            else setTemplateReportData(undefined);
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.template_report_render_data]);


    const handleSubTabsTemplateReportSelect = key => {
        dispatch(setActiveTemplateReportSubTabName(key));
    }

    useEffect(() => {
        if (state.runId !== undefined) {
            if (state.active_template_report_sub_tab_name === 'pdf_report') {
                //handlePdfReportTabClick();
            }
            else if (state.active_template_report_sub_tab_name === 'supplement') {
                handleSupplementTabClick();
            }
            else if (state.active_template_report_sub_tab_name === 'late_flow_metrics') {
                handleLateFlowMetricsTabClick();
            }
            else if (state.active_template_report_sub_tab_name === 'tool_noise') {
                handleToolNoiseTabClick();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.runId, state.active_template_report_sub_tab_name]);

    const handleSupplementTabClick = () => {
        try {
            setSupplementData(undefined);
            let exists = state.supplementary_report_render_data.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getSupplementReportAsync(state.runId, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data === undefined) {
                //dispatch(setOpticsPlotsDataLoading(true));       
                dispatch(getSupplementReportAsync(state.runId, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data !== undefined) {
                dispatch(setSupplementReportRenderData(exists[0].data, state.runId));
                setSupplementData(exists[0].data);
            }
        }
        catch { }
    };

    useEffect(() => {
        try {
            let exists = state.supplementary_report.filter(f => f.runid === state.runId);
            if (exists.length > 0) {
                dispatch(setSupplementReportRenderData(exists[0].data, state.runId));
            }
            else dispatch(getSupplementReportAsync(state.runId, state.jwt));
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.supplementary_report]);

    useEffect(() => {
        try {
            let exists = state.supplementary_report_render_data.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data) {
                setSupplementData(exists[0].data);
            }
            //else setSupplementData(undefined);
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.supplementary_report_render_data]);

    const handleLateFlowMetricsTabClick = () => {
        try {
            setLateFlowMetricsData(undefined);
            let exists = state.late_flow_metrics_data.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getLateFLowMetricsDataAsync(state.runId, state.jwt));
            }
            /*else if (exists.length > 0 && exists[0].data === undefined) {                
                dispatch(getLateFLowMetricsDataAsync(state.runId));
            }*/
            else if (exists.length > 0 && exists[0].data !== undefined) {
                setLateFlowMetricsData(exists[0].data);
            }
        }
        catch { }
    };

    useEffect(() => {
        try {
            let exists = state.late_flow_metrics_data.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getLateFLowMetricsDataAsync(state.runId, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data !== undefined) {
                setLateFlowMetricsData(exists[0].data);
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.late_flow_metrics_data]);

    const handleToolNoiseTabClick = () => {
        try {
            setToolNoiseData(undefined);
            let exists = state.tool_noise_plots.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getToolNoisePlots(state.runId, 'TNALL', false, state.jwt));
            }           
            else if (exists.length > 0 && exists[0].data !== undefined) {
                setToolNoiseData([exists[0].data.plot1, exists[0].data.plot2, exists[0].data.plot3, exists[0].data.plot4]);
            }

            setToolNoiseTableData(undefined);
            let exists1 = state.tool_noise_table_data.filter(f => f.runid === state.runId);
            if (exists1.length === 0) {
                dispatch(getNoiseAndContextDataAsync(state.runId, state.jwt));
            }           
            else if (exists1.length > 0 && exists1[0].data !== undefined) {
                setToolNoiseTableData(exists1[0].data);
            }
        }
        catch { }
    };

    useEffect(() => {
        try {
            let exists = state.tool_noise_plots.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getToolNoisePlots(state.runId, 'TNALL', false, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data !== undefined) {
                setToolNoiseData([exists[0].data.plot1, exists[0].data.plot2, exists[0].data.plot3, exists[0].data.plot4]);
            }           
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.tool_noise_plots]);

    useEffect(() => {
        try {
           let exists = state.tool_noise_table_data.filter(f => f.runid === state.runId);
            if (exists.length === 0) {
                dispatch(getNoiseAndContextDataAsync(state.runId, state.jwt));
            }
            else if (exists.length > 0 && exists[0].data !== undefined) {
                setToolNoiseTableData(exists[0].data);
            }            
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.tool_noise_table_data]);

    return (
        <React.Fragment>
            <Row>
                <Col lg={2} style={{ marginTop: 15 }}>
                    <Nav defaultActiveKey="pdf_report" className="flex-column verticalNav" onSelect={(selectedKey) => handleSubTabsTemplateReportSelect(selectedKey)}>
                        <Nav.Link eventKey="pdf_report"><PictureAsPdfIcon style={{ marginBottom: 5, marginLeft: 5 }} />PDF Report</Nav.Link>
                        <Nav.Link eventKey="supplement"><SummarizeIcon style={{ marginBottom: 5, marginLeft: 5 }} />Supplement</Nav.Link>
                        <Nav.Link eventKey="tool_noise"><SsidChartIcon style={{ marginBottom: 5, }} />Tool Noise</Nav.Link>
                        <Nav.Link eventKey="late_flow_metrics"><TableRowsIcon style={{ marginBottom: 5, marginLeft: 55, }} />Late Flow Metrics</Nav.Link>
                    </Nav>
                </Col>
                <Col lg={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="pdf_report">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col lg={12}>
                                                        {templateReportData !== undefined && <DownloadLink file={templateReportData} file_name={`Template_Report_${state.runId}.pdf`} file_link_label={'Download Report'} />}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {process.env.REACT_APP_RENDER_TEMPLATE_REPORT_PDF_VIEWER === undefined || process.env.REACT_APP_RENDER_TEMPLATE_REPORT_PDF_VIEWER === false ?
                                                        <Col lg={12}>
                                                            {templateReportData !== undefined ? <Frame src={templateReportData} /> : state.is_template_report_loading ? <Row style={{ marginTop: 20 }}><Col lg={12}><LoadingSpinner /></Col></Row> : <ShowAlert type={'warning'} text='Report not found' />}
                                                        </Col>
                                                        :
                                                        <Col lg={12}>
                                                            {templateReportData !== undefined ? <SinglePagePDFViewer pdf={templateReportData} /> : state.is_template_report_loading ? <Row style={{ marginTop: 20 }}><Col lg={12}><LoadingSpinner /></Col></Row> : <ShowAlert type={'warning'} text='Report not found' />}
                                                        </Col>
                                                    }
                                                </Row>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="supplement">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col lg={12}>
                                                        {supplementData !== undefined && <DownloadLink file={supplementData} file_name={`Supplemental_Report_${state.runId}.pdf`} file_link_label={'Download Report'} />}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        {supplementData !==  undefined && !state.is_template_report_loading ? <SinglePagePDFViewer pdf={supplementData} /> : state.is_template_report_loading ? <Row style={{ marginTop: 20 }}><Col lg={12}><LoadingSpinner /></Col></Row> : <ShowAlert type={'warning'} text='Report not found' />}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="tool_noise">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col lg={12}>
                                                        {toolNoiseTableData !==  undefined ?
                                                            <Table bordered hover style={{ marginTop: 20 }} striped>
                                                                <thead>
                                                                    <tr key={Utils.generateKey('mymymy')}>
                                                                        <th key={Utils.generateKey(0)}><strong></strong></th>
                                                                        <th key={Utils.generateKey(1)}><strong>A</strong></th>
                                                                        <th key={Utils.generateKey(2)}><strong>C</strong></th>
                                                                        <th key={Utils.generateKey(3)}><strong>G</strong></th>
                                                                        <th key={Utils.generateKey(4)}><strong>T</strong></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {toolNoiseTableData.length > 0 && toolNoiseTableData.map((item, i) => {
                                                                        return <>
                                                                            <tr key={Utils.generateKey(i * 7)}>
                                                                                <td key={Utils.generateKey(i * 2)}>Context</td>
                                                                                <td key={Utils.generateKey(i * 3)}>{item.C_A}</td>
                                                                                <td key={Utils.generateKey(i * 4)}>{item.C_C}</td>
                                                                                <td key={Utils.generateKey(i * 5)}>{item.C_G}</td>
                                                                                <td key={Utils.generateKey(i * 6)}>{item.C_T}</td>
                                                                            </tr>
                                                                            <tr key={Utils.generateKey(i * 8)}>
                                                                                <td key={Utils.generateKey(i * 9)}>Noise</td>
                                                                                <td key={Utils.generateKey(i * 10)}>{item.N_A}</td>
                                                                                <td key={Utils.generateKey(i * 11)}>{item.N_C}</td>
                                                                                <td key={Utils.generateKey(i * 12)}>{item.N_G}</td>
                                                                                <td key={Utils.generateKey(i * 13)}>{item.N_T}</td>
                                                                            </tr>
                                                                        </>
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                            : state.is_tool_noise_table_data_loading ? <Row style={{ marginTop: 20 }}><Col lg={12}><LoadingSpinner /></Col></Row> : null}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={12}>
                                                        <Row>
                                                            {(toolNoiseData !== undefined && toolNoiseData.length > 0) ? toolNoiseData.map((item, i) => {
                                                                if (item) {
                                                                    return (
                                                                        <Col lg={6} key={`TN${(i + 1) * 2}`}>
                                                                            <Image src={item} />
                                                                        </Col>
                                                                    );
                                                                }
                                                            })
                                                                : state.is_tool_noise_plots_loading ? <Row style={{ marginTop: 20 }}><Col lg={12}><LoadingSpinner /></Col></Row> : <ShowAlert type={'warning'} text='No data' />
                                                            }
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>

                        <Tab.Pane eventKey="late_flow_metrics">
                            <Row>
                                <Col lg={12} style={{ marginTop: 15 }}>
                                    <Card>
                                        <ListGroup variant="flush">
                                            <ListGroup.Item>
                                                <Row>
                                                    <Col lg={12}>
                                                        {lateFlowMetricsData !== undefined ?
                                                            <Table bordered hover style={{ marginTop: 20 }} striped>
                                                                <thead>
                                                                    {lateFlowMetricsData.length > 0 && lateFlowMetricsData.map((item, i) => {
                                                                        if (i === 0) {
                                                                            return <tr key={Utils.generateKey(i)}>
                                                                                {item.map((m, j) => {
                                                                                    if (j <= 3) {
                                                                                        return <th key={Utils.generateKey(j)}>{i === 0 ? <strong>{m}</strong> : m}</th>
                                                                                    }
                                                                                })}
                                                                            </tr>;
                                                                        }
                                                                    })}
                                                                </thead>
                                                                <tbody>
                                                                    {lateFlowMetricsData.length > 0 && lateFlowMetricsData.map((item, i) => {
                                                                        if (i !== 0) {
                                                                            return <tr key={Utils.generateKey(i)}>
                                                                                {item.map((m, j) => {
                                                                                    if (j <= 3) {
                                                                                        return <td key={Utils.generateKey(j)}>{i === 0 ? <strong>{m}</strong> : m}</td>
                                                                                    }
                                                                                })}
                                                                            </tr>;
                                                                        }
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                            : state.is_late_flow_metrics_data_loading ? <Row style={{ marginTop: 20 }}><Col lg={12}><LoadingSpinner /></Col></Row> : <ShowAlert type={'warning'} text='No data' />}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab.Pane>

                    </Tab.Content>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default TemplateReport;