import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import SearchTextRow from './searchtextrow';
import { setCustomFilters, } from "../actions/index";
import { Row, Col, } from "react-bootstrap";
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const RunInfo = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const updateState = (col, data) => {
        let filters = state.custom_filters;
        filters[col] = { ...filters[col], ...data };
        dispatch(setCustomFilters(filters));
    }

    const handleChange = (e, name) => {
        let val = dayjs(new Date(e).toLocaleDateString("en-US")).format('MM/DD/YYYY');
        let filters = state.custom_filters;
        if(val === 'Invalid Date') return;        
        filters['startdatetime'] = { ...filters['startdatetime'], dirty: true, [name]: val };
        dispatch(setCustomFilters(filters));
    }

    const question = (title) => {
        return (
            <FontAwesomeIcon title={`Filter by ${title}`} icon={faQuestionCircle} style={{ width: "16px", height: "16px", color: "#ABABAB" }}/>
        )
    }

    return (
        <Row className="text-center">
            <Col lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <table>
                    <tbody>
                        <tr>
                            <td>Start Date</td>
                            <td>{question('Date')}</td>
                            <td className="text-center w160">
                                <div className="d-flex align-items-center" style={{justifyContent: 'center'}}>
                                    <DatePicker 
                                        value={(state.custom_filters.startdatetime != undefined && state.custom_filters.startdatetime.low) ? dayjs(state.custom_filters.startdatetime.low, 'MM-DD-YYYY').toDate() : null }
                                        onChange={v => handleChange(v, 'low')}
                                        format="MM/DD/YYYY"
                                        label="Date From:" 
                                        className='custom-date-picker'
                                        renderInput={(params) => <TextField {...params} name='low' sx={{width: '150px'}}/>}
                                        />
                                </div>
                            </td>
                            <td>
                                <div className="d-flex align-items-center" >                                 
                                         <DatePicker 
                                            value={(state.custom_filters.startdatetime != undefined && state.custom_filters.startdatetime.high) ? dayjs(state.custom_filters.startdatetime.high, 'MM-DD-YYYY').toDate() : null }
                                            onChange={v => handleChange(v, 'high')}
                                            format="MM/DD/YYYY"
                                            label="Date To:" 
                                            className='custom-date-picker'
                                            renderInput={(params) => <TextField {...params} name='high' sx={{width: '150px'}}/>}
                                            />
                                </div>
                            </td>
                        </tr>

                        <SearchTextRow
                            title="Sys ID" col="sysid"
                            op={state.custom_filters.sysid != undefined ? state.custom_filters.sysid.op : ''} val={state.custom_filters.sysid.val != undefined ? state.custom_filters.sysid.val : ''}
                            placeholder='Type multiple IDs with ","'
                            updateState={updateState} />

                        <SearchTextRow
                            title="Wafer Shelf" col="chuck"
                            op={state.custom_filters.sysid != undefined ? state.custom_filters.chuck.op : ''} val={state.custom_filters.chuck.val != undefined ? state.custom_filters.chuck.val : ''}
                            placeholder='Type multiple values with ","'
                            updateState={updateState} />

                        <SearchTextRow
                            title="Run Id" col="runid"
                            op={state.custom_filters.runid != undefined ? state.custom_filters.runid.op : ''}
                            val={state.custom_filters.runid != undefined ? state.custom_filters.runid.val : ''}
                            placeholder='Type multiple ID with ","'
                            updateState={updateState} />

                        <SearchTextRow
                            title="Instrument Id" col="instrumentId"
                            op={state.custom_filters.instrumentId != undefined ? state.custom_filters.instrumentId.op : ''} val={state.custom_filters.instrumentId != undefined ? state.custom_filters.instrumentId.val : ''}
                            placeholder='e.g. ePCR'
                            updateState={updateState} />

                        <SearchTextRow
                            title="Details" col="details"
                            op={state.custom_filters.details != undefined ? state.custom_filters.details.op : ''} val={state.custom_filters.details != undefined ? state.custom_filters.details.val : ''}
                            placeholder='e.g. 123'
                            updateState={updateState} />

                        <SearchTextRow
                            title="Seq Reagents" col="reagents"
                            op={state.custom_filters.reagents != undefined ? state.custom_filters.reagents.op : ''} val={state.custom_filters.reagents != undefined ? state.custom_filters.reagents.val : ''}
                            placeholder='e.g. 123'
                            updateState={updateState} />

                        <SearchTextRow
                            title="AMP Reagents" col="amp_reagents"
                            op={state.custom_filters.amp_reagents != undefined ? state.custom_filters.amp_reagents.op : ''} val={state.custom_filters.amp_reagents != undefined ? state.custom_filters.amp_reagents.val : ''}
                            placeholder='e.g. 123'
                            updateState={updateState} />

                    </tbody>
                </table>
                </LocalizationProvider>
            </Col>
        </Row>
    );
}

export default RunInfo;