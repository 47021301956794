import {
    LOAD_RUN_LIST_DATA, SET_CURRENT_RUN_DATA, SET_IS_LOADING, GET_SIGNAL_PLOTS_DATA, SET_SIGNAL_PLOTS_RENDER_DATA, GET_HISTOGRAM_PLOTS_DATA, SET_HISTOGRAM_PLOTS_RENDER_DATA, SET_FLOW, SET_TILE_AREA, SET_INSTRUMENT_ID, SET_RUN_ID, GET_CIRCLE_PLOTS_DATA, SET_CIRCLE_PLOTS_RENDER_DATA, SET_IS_SIGNAL_PLOTS_LOADING, SET_IS_HISTOGRAM_PLOTS_LOADING,
    SET_DENSITY_SPINNER, SET_SIGNAL_SPINNER, SET_SNR_SPINNER, SET_STATS_TABLE_DATA, SET_THETA_PROFILING_PLOTS_RENDER_DATA, SET_THETA_PROFILING_PLOTS_RADIUS, GET_RUN_CONFIGURATION_TABLE_DATA, SET_RUN_CONFIGURATION_TABLE_RENDER_DATA, GET_RUN_CONFIGURATION_PLOTS, CHECK_RUN_IS_BARCODED, GET_BEADS_FILTERING_TABLE_DATA, SET_BEADS_FILTERING_TABLE_RENDER_DATA,
    GET_BEADS_FILTERING_PLOTS, GET_BARCODE_STATISTICS_TABLE_DATA, SET_BARCODE_STATISTICS_TABLE_RENDER_DATA, GET_BER_TABLE_DATA, SET_BER_TABLE_RENDER_DATA, GET_BER_HMER_TABLE_DATA, SET_BER_HMER_TABLE_RENDER_DATA, GET_ERROR_PLOTS, GET_RLQ_TABLE_DATA, SET_RLQ_TABLE_RENDER_DATA,
    GET_FMETRICS_TABLE_DATA, SET_FMETRICS_TABLE_RENDER_DATA, GET_BARCODE_COVERAGE_PLOTS, GET_BASE_QUALITY_PLOTS, GET_PHASING_PLOTS, GET_SIGNALS_PLOTS, GET_COVERAGE_GC_PLOTS, GET_UNIFORMITY_PLOTS, GET_UNIFORMITY_SIGNAL_PLOTS, GET_Q_TABLE_DATA, SET_Q_TABLE_RENDER_DATA, GET_HG_BASECALLING_REPORT, GET_EC_BASECALLING_REPORT,
    GET_SIGNALS_LINEARITY_TABLE_DATA, SET_SIGNALS_LINEARITY_TABLE_RENDER_DATA, GET_SIGNALS_LINEARITY_PLOTS, GET_OPTICS_PLOTS_DATA, SET_OPTICS_PLOTS_RENDER_DATA, GET_BEAD_LOSS_PLOTS_DATA, GET_HISTOGRAM_BY_FLOWS_PLOTS_DATA, SET_DENSITY_PLOTS_RENDER_DATA, SET_STATS_DENSITY_TABLE_DATA, GET_CLUMPINGS_TABLE_DATA, SET_CLUMPINGS_TABLE_RENDER_DATA, SET_IS_RUN_DATA_LOADING,
    SET_USER, GET_HEALTH_DATA_TEMPERATURE_PLOTS, SET_HEALTH_DATA_IS_LOADING, SET_HEALTH_DATA_TEMPERATURE_RENDER_DATA, GET_HEALTH_DATA_HUMIDITY_PLOTS, SET_HEALTH_DATA_HUMIDITY_RENDER_DATA, GET_COVERAGE_PLOTS, GET_PHASING_TABLE_DATA, GET_PHASING_TABLE_RENDER_DATA, GET_COVGCBIAS_TABLE_DATA, GET_COVGCBIAS_TABLE_RENDER_DATA, CHECK_VCREPORT_EXIST,
    GET_VCREPORT_PLOTS, IS_MAIN_TABLE_FIRST_PAGE_LOADED, SET_ACTIVE_TAB_NAME, IS_NUM_FLOWS_100_LOADED, LOAD_AMP_LIST_DATA, SET_IS_AMP_DATA_LOADING, SET_CURRENT_AMP_RUN_DATA, SET_AMP_RUN_ID, GET_AMP_PLOTS_DATA, SET_IS_AMP_PLOTS_DATA_LOADING, SET_IS_OPTICS_PLOTS_DATA_LOADING, SET_ACTIVE_INSTR_HEALTH_SUB_TAB_NAME, GET_OPTICS_PLOTS_LASER_DATA, SET_OPTICS_PLOTS_LASER_RENDER_DATA,
    SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING, GET_IMG_DEFORM_DATA, SET_IMG_DEFORM_RENDER_DATA, SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING, GET_BEAD_LOSS_PLOTS_DATA_CSV, SET_BEAD_LOSS_DATA_LOADING, GET_SAM_REPORT, SET_SAM_REPORT_IS_LOADING, GET_FWHM_PLOTS_DATA, SET_FWHM_PLOTS_IS_LOADING, GET_FWHM_PLOTS_CAMERA_DATA, SET_TEMPLATE_REPORT_IS_LOADING,
    GET_TEMPLATE_REPORT, SET_TEMPLATE_REPORT_RENDER_DATA, GET_SUPPLEMENTARY_REPORT, SET_SUPPLEMENTARY_REPORT_IS_LOADING, SET_SUPPLEMENTARY_REPORT_RENDER_DATA, SET_ACTIVE_TEMPLATE_REPORT_SUB_TAB_NAME, SET_LATE_FLOW_METRICS_IS_LOADING, GET_LATE_FLOW_METRICS_DATA, GET_TOOL_NOISE_PLOTS, SET_TOOL_NOISE_PLOTS_IS_LOADING, GET_TOOL_NOISE_TABLE_DATA,
    SET_TOOL_NOISE_TABLE_DATA_IS_LOADING, SET_CUSTOM_FILTERS, SET_CUSTOM_FILTERS_APPLIED, SET_RUN_LIST_DATA_FILTERED, SET_RUN_LIST_DATA, SET_RUN_LIST_DOWNLOAD_DATA, SET_ALL_RUN_LIST_DATA_SHOWN_BY_FLOW_NUM_100, SET_ANALYSIS_COMMENT_DATA_SAVED, SET_DETAILS_DATA_SAVED, SET_ISAUTHENTICATED, LOGOUT_CURRENT_USER, RESET_STATE, SET_EDIT_MODE,
    SET_RUN_LIST_DATA_SEARCHED, SET_NUMBER_OF_CUSTOM_FILTERS_APPLIED, SET_SEARCH_TERM, SET_SEARCH_TERM_AMP, SET_RUN_LIST_DATA_SEARCHED_AMP, SET_RUN_LIST_AMP_DOWNLOAD_DATA, IS_EDIT_ALLOWED, SET_ERROR_PLOT_IS_LOADING, SET_READ_LENGTH_PLOT_IS_LOADING, GET_READ_LENGTH_PLOTS, SET_READ_LENGTH_PLOTS_BY_CAMERA_VALUE, 
    SET_ERROR_PLOTS_BY_CAMERA_VALUE, SET_RSQ_PLOTS_BY_CAMERA_VALUE, GET_RSQ_PLOT, SET_RSQ_PLOT_IS_LOADING, GET_POLY_PLOTS, SET_BARCODE_COVERAGE_PLOTS_IS_LOADING, GET_FWHM_PLOTS, RUN_SUMMARY_DENSITY_PLOT_BY_CAMERA_VALUE, GET_RUN_SUMMARY_DENSITY_PLOT_DATA, SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING, SET_RUN_SUMMARY_DENSITY_PLOT_RENDER_DATA,
    SET_VERSION, SET_BEADS_FILTERING_PLOTS_IS_LOADING, READS_FILTERING_PLOT_BY_CAMERA_VALUE, SET_FMETRICS_DATA_IS_LOADING, GET_COVERAGE_PLOTS_DATA, SET_TEMPLATE_REPORT_EXIST, SET_HG_BASECALLING_REPORT_EXIST, SET_EC_BASECALLING_REPORT_EXIST, GET_CHART_VIEW_PLOTS_DATA, SET_CHART_VIEW_PLOTS_DATA_IS_LOADING, GET_SIGNALS_PLOTS_PNG, 
    GET_BASE_QUALITY_BER_PER_FLOW_PLOT, SET_ALERT, CLEAR_ALERT, SET_IS_UNIFIED_ROW_SELECTED, GET_HOMO_INDEL_ERROR, GET_BARCODE_DETAILS_READ_LENGTH_PLOT_DATA, SET_POLY_PLOTS_BY_CAMERA_VALUE, SET_POLY_PLOTS_IS_LOADING, ADD_PLOTLY_PLOT_TO_LIST, GET_FWHM_CSV_DATA, SET_IS_AMP_SINGLE_RUN_LOADING, GET_AMP_SINGLE_RUN_DATA, SET_BY_CAMERA_VALUE, SET_SHOW_FROZEN_HEADER,
    SET_JWT, SET_JWT_EXP, SET_CALCULATE_COVERAGE_DATA, TIME_ZONE_SELECTED, POPULATE_RUNIDS, SET_IS_IMPORT_FIELD_LOADING, GET_RUNINFO_DATA, SET_CUSTOM_STORAGE, GET_DISKSPACE, SET_ALERT_STORAGE_RUN, DELETE_RUN_REQUEST_STATUS, CALCULATED_CUSTOM_STORAGE_SPACE, ACTIVE_BARCODE_TABLE_BUTTON, ADD_PLOTLY_URI_LIST, ADD_RUN_DETAILS_TABLE_PDF, ADD_BEADS_FFILTER_TABLE_PDF,
    SET_VALID_DATA, IS_VALID_DATA_LOADING, LOAD_VALID_DATA, VALIDATION_STATUS, LOAD_H5PLOT_VALID_DATA, SET_H5PLOTS_VALID_DATA, SINGLE_VALID_RUN_DATA, LOAD_VALID_AMP_DATA, FWHM_VALID_RUN_DATA, GET_RUN_CONFIGURATION_VALIDATED_DATA, GET_ERROR_PLOTS_VALIDATED, GET_RSQ_PLOT_VALIDATED, GET_BARCODE_STATISTICS_VALIDATED,
    GET_CLUMPINGS_VALIDATED, GET_LINEARITY_VALIDATED, GET_BEADS_FILTERING_VALIDATED, GET_BER_TABLE_VALIDATED, GET_BER_HMER_TABLE_VALIDATED, GET_RLQ_TABLE_VALIDATED, GET_FMETRICS_TABLE_VALIDATED, GET_Q_TABLE_VALIDATED, GET_READS_FILTERING_PLOTS_VALIDATED, GET_PHASING_VALIDATED_DATA, GET_VALIDATED_READ_LENGTH_PLOTS, GET_SIGNAL_DATA_VALIDATED,
    GET_PPMSEQ_REPORT_LIST, SET_COLUMN_TYPE, SET_BARCODE_COVERAGE_BARCODE, SET_EXPANDED_RUNID, CLEAR_EXPANDED_RUNID
} from "../constants/action-types";
import * as Utils from '../../utils/utils';

const getInitialState = () => {
    const storedActiveBarcodeTableButton = localStorage.getItem('active_barcode_table_button');
    const storedCustomCoverageValues = localStorage.getItem('customStorageValues') !== '{}' ? localStorage.getItem('customStorageValues') : null;
    const storedTimeZone = localStorage.getItem('time_zone') ? localStorage.getItem('time_zone') : 'Pacific Standard Time';
    return {
        user: undefined,
        version: '1.0.0.0',
        isAuthenticated: false,
        runlist: [],
        runlist_filtered: [],
        runlist_searched: [],
        runlist_searched_amp: [],
        amplist: [],
        amp_plots_data: [],
        active_tab_name: 'signals',
        active_instr_health_sub_tab_name: 'run_time',
        active_template_report_sub_tab_name: 'pdf_report',
        current_run_data: [],
        current_amp_run_data: [],
        is_run_data_loading: false,
        is_amp_data_loading: false,
        is_amp_plots_data_loading: false,
        isLoading: false,
        isFirstPageloaded: false,
        isNumFlows100Loaded: false,
        signal_plots_data: [],
        signal_plots_render_data: [],
        histogram_plots_data: [],
        histogram_plots_render_data: [],
        flow: 1,
        tileArea: [],
        instrumentId: null,
        runId: null,
        ampRunId: null,
        isRunBaseLine: false,
        circle_plots_data: [],
        circle_plots_render_data: [],
        isSignalPloatsLoading: true,
        isHistogramPloatsLoading: true,
        spinnerDensity: {},
        spinnerSignal: {},
        spinnerSNR: {},
        statsTable1Data: [],
        theta_profiling_plots_render_data: {},
        theta_profiling_plots_radius: [],
        run_config_table_data: [],
        run_config_table_render_data: [],
        run_config_plots: [],
        isRunBarcoded: [],
        beads_filtering_table_data: [],
        beads_filtering_table_render_data: [],
        beads_filtering_plots: [],
        barcode_statistics_table_data: [],
        barcode_statistics_table_render_data: [],
        ber_table_data: undefined,
        ber_table_render_data: [],
        ber_hmer_table_data: [],
        ber_hmer_table_render_data: [],
        error_plots: [],
        rlq_table_data: [],
        rlq_table_render_data: [],
        fmetrics_table_data: undefined,
        fmetrics_table_render_data: [],
        is_fmetrics_table_data_loading: false,
        barcode_coverage_plots: [],
        base_quality_plots: [],
        phasing_plots: [],
        signals_plots: [],
        signals_plots_png: [],
        coverage_gc_plots: [],
        uniformity_plots: [],
        uniformity_signal_plots: [],
        q_table_data: {},
        q_table_render_data: [],
        hg_basecalling_report: undefined,
        is_hg_basecalling_report_exist: false,
        ec_basecalling_report: undefined,
        is_ec_basecalling_report_exist: false,
        signals_linearity_table_data: [],
        signals_linearity_table_render_data: [],
        signals_linearity_plots: [],
        bead_loss_plots: [],
        is_bead_loss_data_loading: false,
        histogram_by_flow_plots_data: [],
        density_plots_render_data: [],
        statsTableDensityData: [],
        clumpings_table_data: [],
        clumpings_table_render_data: [],
        optics_plots_data_is_loading: false,
        optics_plots_data: [],
        optics_plots_render_data: [],
        optics_plots_laser_data_is_loading: false,
        optics_plots_laser_data: [],
        optics_plots_laser_render_data: [],
        health_data_tmpr: [],
        temperature_plot_render_data: [],
        health_data_is_loading: false,
        health_data_humidity: [],
        humidity_plot_render_data: [],
        coverage_plots: [],
        phasing_table_data: [],
        phasing_table_render_data: [],
        covgcbias_table_data: [],
        covgcbias_table_render_data: [],
        vcreport_exists: false,
        vcreport_plots: [],
        img_deform_data: [],
        img_deform_render_data: [],
        isImgDeformDataLoading: true,
        sam_report: [],
        is_sam_report_loading: false,
        fwhm_plots_data: [],
        is_fwhm_plot_loading: false,
        fwhm_plots_camera_data: [],
        template_report: [],
        is_template_report_exist: [],
        is_template_report_loading: false,
        template_report_render_data: [],
        supplementary_report: [],
        is_supplementary_report_loading: false,
        supplementary_report_render_data: [],
        late_flow_metrics_data: [],
        is_late_flow_metrics_data_loading: false,
        tool_noise_plots: [],
        is_tool_noise_plots_loading: false,
        tool_noise_table_data: [],
        is_tool_noise_table_data_loading: false,
        custom_filters: {},
        is_custom_filters_applied: false,
        runlist_download_data: [],
        runlist_amp_download_data: [],
        is_all_run_list_data_shown: false,
        is_details_data_saved: false,
        is_analysis_comments_data_saved: false,
        is_in_edit_mode: false,
        number_of_custom_filters_applied: 0,
        search_term: [],
        search_term_amp: [],
        is_edit_allowed: false,
        is_error_plot_loading: false,
        is_read_length_plot_loading: false,
        read_length_plots: [],
        read_length_plots_by_camera_value: 'unified',
        error_plots_by_camera_value: 'unified',
        rsq_plots: [],
        rsq_plots_by_camera_value: 'unified',
        is_rsq_plot_loading: false,
        poly_plots_data: [],
        is_barcode_coverage_plot_loading: false,
        run_summary_density_plots_by_camera_value: 'unified',
        run_summary_density_plots_data: [],
        run_summary_density_plots_render_data: [],
        run_summary_density_plots_is_loading: false,
        beads_filtering_plot_is_loading: false,
        reads_filtering_plots_by_camera_value: 'unified',
        coverage_plots_data: undefined,
        is_coverage_plots_data_loading: false,
        char_view_plots_data: undefined,
        is_chart_view_plots_data_loading: false,
        base_quality_ber_per_flow_plot_data: undefined,
        error: {
            errorMessage: '',
            errorCode: '',
            errorType: ''
        },
        is_unified_row_selected: undefined,
        homo_indel_error: undefined,
        barcode_details_read_length_plot: undefined,
        poly_plots_by_camera_value: 'unified',
        is_poly_plot_loading: false,
        plotly_plots_list: [],
        fwhm_csv_data: [],
        runlist_amp_single_run_download_data: [],
        amp_single_run_csv_loading: false,
        by_camera_value_global: 'unified',
        show_frozen_header: true,
        validated_single_run: [],
        validated_runlist_data: [],
        validated_amplist_data: [],
        validated_h5plot_data: [],
        set_runlist_validated_data: [],
        set_h5plot_validated_data: [],
        is_validated_data_loading: false,
        validation_status: false,
        calculate_coverage_data: [],
        fwhm_validated_data: [],
        run_configuration_validated_data: [],
        error_plots_validated: [],
        rsq_plots_validated: [],
        barcode_statistics_validated: [],
        clumpings_validated: [],
        linearity_validated: [],
        phasing_validated: [],
        beads_filtered_validated: [],
        ber_hmer_validated: [],
        ber_validated: [],
        rlq_validated: [],
        fmetrics_validated: [],
        q_validated: [],
        read_length_validated: [],
        signal_linearity_validated: [],
        jwt: undefined,
        jwt_exp: undefined,
        run_info: undefined,
        custom_storage: storedCustomCoverageValues || {
            alertRun: 50,
            allRuns: 18,
            baseCallingQuality: 6, 
            signalQualityWafer: 6, 
            systemDebug: 3,
            ampRuns: 3
        },
        diskspace: undefined,
        alert_storage_status: false,
        delete_run_request_status: false,
        calculated_storage_free_space: undefined,
        active_barcode_table_button: storedActiveBarcodeTableButton || '1k',
        plotly_uri_list: [],
        run_details_table_pdf: [],
        beads_filter_table_pdf: [],
        time_zone_selected: storedTimeZone,
        populate_runids: [],
        is_import_field_loading: false,
        ppmSeqReportList: [],
        columnType: null,
        barcode_coverage_selected_barcode: 'TT', 
        expandedRunIds: {},
    }
}

const rootReducer = (state = getInitialState(), action) => {
    if (action.type === SET_USER) {
        return { ...state, user: action.payload };
    }

    if(action.type === SET_IS_AMP_SINGLE_RUN_LOADING){
        return { ...state, amp_single_run_csv_loading: action.payload }
    }

    if(action.type === GET_AMP_SINGLE_RUN_DATA){
        return {
            ...state,
            runlist_amp_single_run_download_data: action.payload
        }
    }

    if (action.type === SET_IS_OPTICS_PLOTS_DATA_LOADING) {
        return { ...state, optics_plots_data_is_loading: action.payload };
    }

    if (action.type === SET_IS_OPTICS_PLOTS_LASER_DATA_LOADING) {
        return { ...state, optics_plots_laser_data_is_loading: action.payload };
    }

    if (action.type === IS_NUM_FLOWS_100_LOADED) {
        return { ...state, isNumFlows100Loaded: action.payload };
    }

    if (action.type === SET_BEAD_LOSS_DATA_LOADING) {
        return { ...state, is_bead_loss_data_loading: action.payload };
    }

    if (action.type === SET_ACTIVE_TAB_NAME) {
        return { ...state, active_tab_name: action.payload };
    }

    if (action.type === SET_ACTIVE_INSTR_HEALTH_SUB_TAB_NAME) {
        return { ...state, active_instr_health_sub_tab_name: action.payload };
    }

    if (action.type === SET_ACTIVE_TEMPLATE_REPORT_SUB_TAB_NAME) {
        return { ...state, active_template_report_sub_tab_name: action.payload };
    }

    if (action.type === LOAD_RUN_LIST_DATA) {
        const updatedPayload = action.payload?.map(data => ({...data,
            startdatetime: data?.startdatetime && data?.startdatetime !== undefined && data?.startdatetime !== null ? Utils.restructureDate(Utils.getStartDate(data?.startdatetime)) : data?.startdatetime,
            reagents: Utils.generateSeqReagentsString(data),
            instrumentId: data?.amp_instrument_id && data?.amp_instrument_id !== undefined && data?.amp_instrument_id !== null ? data?.amp_instrument_id : data?.amp_instrument_id,
            amp_reagents: Utils.generateAMPReagentsString(data),
            feasible_throughput: data?.num_reads_pass_filter && data?.num_reads_pass_filter !== undefined && data?.num_reads_pass_filter !== null && data?.num_reads_pass_filter !== '' ? (((parseInt(data?.numflows) - 29) * 0.75 * parseInt(data?.num_reads_pass_filter) * 1000000) / Math.pow(10, 12)).toFixed(3) : data?.num_reads_pass_filter,
            pf_output_reads: Utils.getThirdElement(data?.analysisstatus) ? (data?.pf_output_reads && data?.pf_output_reads !== undefined && data?.pf_output_reads !== null && Number.isInteger(Utils.tryParseInt(data?.pf_output_reads, data?.pf_output_reads)) ? parseInt(Utils.tryParseInt(data?.pf_output_reads, data?.pf_output_reads) / Math.pow(10, 6)) : '') : "",
            num_reads_pass_filter: Utils.getThirdElement(data?.analysisstatus) ? (data?.num_reads_pass_filter && data?.num_reads_pass_filter !== undefined  && data?.num_reads_pass_filter !== null ? data?.num_reads_pass_filter : data?.num_reads_pass_filter) : '',
            runstatus: data?.isruncompleted && data?.isruncompleted === 1 ? '100' : data?.runstatus,
            aligned_bases: Utils.getThirdElement(data?.analysisstatus) ? (data?.aligned_bases && data?.aligned_bases !== undefined &&  data?.aligned_bases !== null ? data?.aligned_bases : '') : '',
            f95_30x_min: Utils.getThirdElement(data?.analysisstatus) ? (data?.f95_30x_min && data?.f95_30x_min !== undefined && data?.f95_30x_min !== null ? data?.f95_30x_min : '') : '',
            f95_30x_max: Utils.getThirdElement(data?.analysisstatus) ? (data?.f95_30x_max && data?.f95_30x_max !== undefined && data?.f95_30x_max !== null ? data?.f95_30x_max : '') : '',
            rl: Utils.getThirdElement(data?.analysisstatus) ? (data?.rl && data?.rl !== undefined && data?.rl !== null ? data?.rl : '') : '',
            monoclonal_perc: Utils.getThirdElement(data?.analysisstatus) ? (data?.monoclonal_perc && data?.monoclonal_perc !== undefined && data?.monoclonal_perc !== null && data?.monoclonal_perc !== 'NaN' ? data?.monoclonal_perc : '') : '',
            non_sample_reads: Utils.getThirdElement(data?.analysisstatus) ? (data?.non_sample_reads && data?.non_sample_reads !== undefined &&  data?.non_sample_reads !== null ? data?.non_sample_reads : '') : '',
            control_mrl: Utils.getThirdElement(data?.analysisstatus) ? (data?.control_mrl && data?.control_mrl !== undefined && data?.control_mrl !== null ? parseInt(data?.control_mrl) : '') : '',
            subRows: data.subRows?.map(subRow => ({
                ...subRow,
                startdatetime:
                  subRow.startdatetime && subRow.startdatetime !== undefined && subRow.startdatetime !== null
                    ? Utils.restructureDate(Utils.getStartDate(subRow.startdatetime))
                    : subRow.startdatetime,
                    analysisMetaDataTime: Utils.elapsedValue(data?.analysisstatus) ? (subRow?.analysisMetaDataTime && subRow?.analysisMetaDataTime !== undefined && subRow?.analysisMetaDataTime !== null ? subRow?.analysisMetaDataTime : undefined) : undefined
              })),
        }));
        return { ...state, runlist: updatedPayload };
    }

    if (action.type === SET_RUN_LIST_DATA_FILTERED) {
        return { ...state, runlist_filtered: action.payload };
    }

    if (action.type === SET_RUN_LIST_DATA) {
        return { ...state, runlist: action.payload };
    }

    if (action.type === SET_RUN_LIST_DATA_SEARCHED) {
        return { ...state, runlist_searched: action.payload };
    }

    if (action.type === SET_RUN_LIST_DATA_SEARCHED_AMP) {
        return { ...state, runlist_searched_amp: action.payload };
    }

    if (action.type === LOAD_AMP_LIST_DATA) {
        return { ...state, amplist: action.payload };
    }

    if (action.type === SET_CURRENT_RUN_DATA) {
        return { ...state, current_run_data: action.payload };
    }

    if (action.type === SET_CURRENT_AMP_RUN_DATA) {
        return { ...state, current_amp_run_data: action.payload };
    }

    if (action.type === SET_IS_LOADING) {
        return { ...state, isLoading: action.payload };
    }

    if (action.type === IS_MAIN_TABLE_FIRST_PAGE_LOADED) {
        return { ...state, isFirstPageloaded: action.payload };
    }

    if (action.type === SET_IS_RUN_DATA_LOADING) {
        return { ...state, is_run_data_loading: action.payload };
    }

    if (action.type === SET_IS_AMP_DATA_LOADING) {
        return { ...state, is_amp_data_loading: action.payload };
    }

    if (action.type === SET_IS_AMP_PLOTS_DATA_LOADING) {
        return { ...state, is_amp_plots_data_loading: action.payload };
    }

    if (action.type === GET_AMP_PLOTS_DATA) {              
        return {
            ...state,
            amp_plots_data: state.amp_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.amp_plots_data, { runid: action.runid, data: action.payload }] : [...state.amp_plots_data]
        }
    }

    if (action.type === GET_SIGNAL_PLOTS_DATA) {
        return {
            ...state,
            signal_plots_data: state.signal_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.signal_plots_data, { runid: action.runid, data: action.payload }] : [...state.signal_plots_data]
        }
    }

    if (action.type === SET_SIGNAL_PLOTS_RENDER_DATA) {
        return {
            ...state,
            signal_plots_render_data: state.signal_plots_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.signal_plots_render_data, { runid: action.runid, data: action.payload }] : [...state.signal_plots_render_data]
        }
    }

    if (action.type === GET_HISTOGRAM_PLOTS_DATA) {       
        return {
            ...state,
            histogram_plots_data: state.histogram_plots_data.filter(f => f.runid === action.runid && f.flow === action.flow).length === 0 ? [...state.histogram_plots_data, { runid: action.runid, flow: action.flow, data: action.payload }] : [...state.histogram_plots_data]
        }
    }

    if (action.type === SET_HISTOGRAM_PLOTS_RENDER_DATA) {       
        return {
            ...state,
            histogram_plots_render_data: state.histogram_plots_render_data.filter(f => f.runid === action.runid && f.flow === action.flow).length === 0 ? [...state.histogram_plots_render_data, { runid: action.runid, flow: action.flow, data: action.payload }] : [...state.histogram_plots_render_data]
        }
    }

    if (action.type === SET_FLOW) {
        return { ...state, flow: action.payload };
    }

    if (action.type === SET_TILE_AREA) {        
        return {
            ...state,
            tileArea: state.tileArea.filter(f => f.runid === action.runid && f.instrId === action.instrId).length === 0 ? [...state.tileArea, { runid: action.runid, instrId: action.instrId, data: action.payload }] : [...state.tileArea]
        }
    }

    if (action.type === SET_INSTRUMENT_ID) {
        return { ...state, instrumentId: action.payload };
    }

    if (action.type === SET_RUN_ID) {
        return { ...state, runId: action.payload };
    }

    if (action.type === SET_AMP_RUN_ID) {
        return { ...state, ampRunId: action.payload };
    }

    if (action.type === GET_CIRCLE_PLOTS_DATA) {       
        return {
            ...state,
            circle_plots_data: state.circle_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.circle_plots_data, { runid: action.runid, data: action.payload }] : [...state.circle_plots_data]
        }
    }

    if (action.type === SET_CIRCLE_PLOTS_RENDER_DATA) {       
        return {
            ...state,
            circle_plots_render_data: state.circle_plots_render_data.filter(f => f.runid === action.runid && f.flow === action.flow).length === 0 ? [...state.circle_plots_render_data, { runid: action.runid, flow: action.flow, data: action.payload }] : [...state.circle_plots_render_data]
        }
    }

    if (action.type === SET_IS_SIGNAL_PLOTS_LOADING) {
        return { ...state, isSignalPloatsLoading: action.payload };
    }

    if (action.type === SET_IS_HISTOGRAM_PLOTS_LOADING) {
        return { ...state, isHistogramPloatsLoading: action.payload };
    }

    if (action.type === SET_DENSITY_SPINNER) {
        return { ...state, spinnerDensity: action.payload };
    }

    if (action.type === SET_SIGNAL_SPINNER) {
        return { ...state, spinnerSignal: action.payload };
    }

    if (action.type === SET_SNR_SPINNER) {
        return { ...state, spinnerSNR: action.payload };
    }

    if (action.type === SET_STATS_TABLE_DATA) {       
        return {
            ...state,
            statsTable1Data: state.statsTable1Data.filter(f => f.runid === action.runid && f.flow === action.flow).length === 0 ? [...state.statsTable1Data, { runid: action.runid, flow: action.flow, data: action.payload }] : [...state.statsTable1Data]
        }
    }

    if (action.type === SET_THETA_PROFILING_PLOTS_RENDER_DATA) {        
        return {
            ...state,
            theta_profiling_plots_render_data: state.theta_profiling_plots_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.theta_profiling_plots_render_data, { runid: action.runid, data: action.payload }] : [...state.theta_profiling_plots_render_data]
        }
    }

    if (action.type === SET_THETA_PROFILING_PLOTS_RADIUS) {       
        return {
            ...state,
            theta_profiling_plots_radius: state.theta_profiling_plots_radius.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.theta_profiling_plots_radius, { runid: action.runid, data: action.payload }] : [...state.theta_profiling_plots_radius]
        }
    }

    if (action.type === GET_RUN_CONFIGURATION_TABLE_DATA) {       
        return {
            ...state,
            run_config_table_data: state.run_config_table_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.run_config_table_data, { runid: action.runid, data: action.payload }] : [...state.run_config_table_data]
        }
    }   

    if (action.type === SET_RUN_CONFIGURATION_TABLE_RENDER_DATA) {       
        return {
            ...state,
            run_config_table_render_data: state.run_config_table_render_data.filter(f => f.runid === action.runid && f.camera === action.camera).length === 0 ? [...state.run_config_table_render_data, { runid: action.runid, camera: action.camera, data: action.payload }] : [...state.run_config_table_render_data]
        }
    }

    if (action.type === GET_RUN_CONFIGURATION_PLOTS) {        
        return {
            ...state,
            run_config_plots: state.run_config_plots.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.run_config_plots, { runid: action.runid, data: action.payload }] : [...state.run_config_plots]
        }
    }

    if (action.type === CHECK_RUN_IS_BARCODED) {       
        return {
            ...state,
            isRunBarcoded: state.isRunBarcoded.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.isRunBarcoded, { runid: action.runid, data: action.payload }] : [...state.isRunBarcoded]
        }
    }

    if (action.type === GET_BEADS_FILTERING_TABLE_DATA) {        
        return {
            ...state,
            beads_filtering_table_data: state.beads_filtering_table_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.beads_filtering_table_data, { runid: action.runid, data: action.payload }] : [...state.beads_filtering_table_data]
        }
    }

    if (action.type === SET_BEADS_FILTERING_TABLE_RENDER_DATA) {       
        return {
            ...state,
            beads_filtering_table_render_data: state.beads_filtering_table_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.beads_filtering_table_render_data, { runid: action.runid, data: action.payload }] : [...state.beads_filtering_table_render_data]
        }
    }

    if (action.type === GET_BEADS_FILTERING_PLOTS) {       
        return {
            ...state,
            beads_filtering_plots: state.beads_filtering_plots.filter(f => f.runid === action.runid && f.camera === action.camera).length === 0 ? [...state.beads_filtering_plots, { runid: action.runid, camera: action.camera, data: action.payload }] : [...state.beads_filtering_plots]
        }
    }

    if (action.type === GET_BARCODE_STATISTICS_TABLE_DATA) {        
        return {
            ...state,
            barcode_statistics_table_data: state.barcode_statistics_table_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.barcode_statistics_table_data, { runid: action.runid, data: action.payload }] : [...state.barcode_statistics_table_data]
        }
    }

    if (action.type === SET_BARCODE_STATISTICS_TABLE_RENDER_DATA) {        
        return {
            ...state,
            barcode_statistics_table_render_data: state.barcode_statistics_table_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.barcode_statistics_table_render_data, { runid: action.runid, data: action.payload }] : [...state.barcode_statistics_table_render_data]
        }
    }

    if (action.type === GET_BER_TABLE_DATA) {
        return { ...state, ber_table_data: action.payload };
    }

    if (action.type === SET_BER_TABLE_RENDER_DATA) {
        return { ...state, ber_table_render_data: action.payload };
    }

    if (action.type === GET_BER_HMER_TABLE_DATA) {
        return { ...state, ber_hmer_table_data: action.payload };
    }

    if (action.type === SET_BER_HMER_TABLE_RENDER_DATA) {
        return { ...state, ber_hmer_table_render_data: action.payload };
    }

    if (action.type === GET_ERROR_PLOTS) {       
        return {
            ...state,
            error_plots: state.error_plots.filter(f => f.runid === action.runid && f.camera === action.camera).length === 0 ? [...state.error_plots, { runid: action.runid, camera: action.camera, data: action.payload }] : [...state.error_plots]
        }        
    }

    if (action.type === GET_RLQ_TABLE_DATA) {
        return { ...state, rlq_table_data: action.payload };
    }

    if (action.type === SET_RLQ_TABLE_RENDER_DATA) {
        return { ...state, rlq_table_render_data: action.payload };
    }

    if (action.type === GET_FMETRICS_TABLE_DATA) {
        return { ...state, fmetrics_table_data: action.payload };
    }

    if (action.type === SET_FMETRICS_TABLE_RENDER_DATA) {
        return { ...state, fmetrics_table_render_data: action.payload };
    }

    if (action.type === GET_BARCODE_COVERAGE_PLOTS) {
        const existingRun = state.barcode_coverage_plots.find(f => f.runid === action.runid);
        if (existingRun) {
            // Run exists, check if the barcode exists within the data array
            const existingBarcode = existingRun.data.data.find(b => b.barcode === action.payload.data[0].barcode);
    
            // If the barcode exists, update it, otherwise add the new barcode
            const updatedBarcodes = existingBarcode
                ? existingRun.data.data.map(b => b.barcode === action.payload.data[0].barcode ? action.payload.data[0] : b)
                : [...existingRun.data.data, action.payload.data[0]];
            
            return {
                ...state,
                barcode_coverage_plots: state.barcode_coverage_plots.map(f => 
                    f.runid === action.runid 
                        ? { ...existingRun, data: { ...existingRun?.data, data: updatedBarcodes } } 
                        : f
                )
            };
        } else {
            // If the runid doesn't exist, add a new runid with the barcode data
            return {
                ...state,
                barcode_coverage_plots: [...state.barcode_coverage_plots, { runid: action.runid, data: action.payload }]
            };
        }
    }
    
    if(action.type === SET_CALCULATE_COVERAGE_DATA){
        let data = Utils.calculateAndReturnData(action.payload);
        return {
            ...state,
            calculate_coverage_data: data
        }
    }

    if (action.type === GET_BASE_QUALITY_PLOTS) {
        return {
            ...state,
            base_quality_plots: state.base_quality_plots.filter(f => f.runid === action.runid).length === 0 ? [...state.base_quality_plots, { runid: action.runid, data: action.payload }] : [...state.base_quality_plots]
        } 
    }

    if (action.type === GET_PHASING_PLOTS) {
        return { ...state, phasing_plots: action.payload };
    }

    if (action.type === GET_SIGNALS_PLOTS) {
        return { ...state, signals_plots: action.payload };
    }

    if (action.type === GET_SIGNALS_PLOTS_PNG) {
        return { ...state, signals_plots_png: action.payload };
    }

    if (action.type === GET_COVERAGE_GC_PLOTS) {
        return { ...state, coverage_gc_plots: action.payload };
    }

    if (action.type === GET_UNIFORMITY_PLOTS) {
        return { ...state, uniformity_plots: action.payload };
    }

    if (action.type === GET_UNIFORMITY_SIGNAL_PLOTS) {
        return { ...state, uniformity_signal_plots: action.payload };
    }

    if (action.type === GET_Q_TABLE_DATA) {
        const jsonString = {...action.payload};
        return { ...state, q_table_data: jsonString };
    }

    if (action.type === SET_Q_TABLE_RENDER_DATA) {
        return { ...state, q_table_render_data: action.payload };
    }

    if (action.type === GET_HG_BASECALLING_REPORT) {
        return { ...state, hg_basecalling_report: action.payload };
    }

    if (action.type === SET_HG_BASECALLING_REPORT_EXIST) {
        return { ...state, is_hg_basecalling_report_exist: action.payload };
    }

    if (action.type === SET_EC_BASECALLING_REPORT_EXIST) {
        return { ...state, is_ec_basecalling_report_exist: action.payload };
    }
    
    if (action.type === GET_SAM_REPORT) {        
        return {
            ...state,
            sam_report: state.sam_report.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.sam_report, { runid: action.runid, data: action.payload }] : [...state.sam_report]
        }
    }

    if (action.type === GET_EC_BASECALLING_REPORT) {
        return { ...state, ec_basecalling_report: action.payload };
    }

    if (action.type === GET_SIGNALS_LINEARITY_TABLE_DATA) {
        return { ...state, signals_linearity_table_data: action.payload };
    }

    if (action.type === SET_SIGNALS_LINEARITY_TABLE_RENDER_DATA) {
        return { ...state, signals_linearity_table_render_data: action.payload };
    }

    if (action.type === GET_SIGNALS_LINEARITY_PLOTS) {
        return { ...state, signals_linearity_plots: action.payload };
    }

    if (action.type === GET_OPTICS_PLOTS_DATA) {       
        return {
            ...state,
            //optics_plots_data: state.optics_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.optics_plots_data, { runid: action.runid, flow: action.flow, beadsType: action.beadsType, data: action.payload }] : [...state.optics_plots_data]
            optics_plots_data: state.optics_plots_data.filter(f => f.runid === action.runid && f.flow === action.flow && f.type === action.beadsType).length === 0 ? [...state.optics_plots_data, { runid: action.runid, flow: action.flow, beadsType: action.beadsType, data: action.payload }] : [...state.optics_plots_data]
        }
    }

    if (action.type === SET_OPTICS_PLOTS_RENDER_DATA) {        
        return {
            ...state,
            optics_plots_render_data: state.optics_plots_render_data.filter(f => f.runid === action.runid && f.flow === action.flow && f.type === action.beadsType).length === 0 ? [...state.optics_plots_render_data, { runid: action.runid, flow: action.flow, beadsType: action.beadsType, data: action.payload }] : [...state.optics_plots_render_data]
        }
    }

    if (action.type === GET_OPTICS_PLOTS_LASER_DATA) {        
        return {
            ...state,
            optics_plots_laser_data: state.opticsoptics_plots_laser_data_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.optics_plots_laser_data, { runid: action.runid, data: action.payload }] : [...state.optics_plots_laser_data]
        }
    }

    if (action.type === SET_OPTICS_PLOTS_LASER_RENDER_DATA) {       
        return {
            ...state,
            optics_plots_laser_render_data: state.optics_plots_laser_render_data.filter(f => f.runid === action.runid && f.flow === action.flow && f.type === action.beadsType).length === 0 ? [...state.optics_plots_laser_render_data, { runid: action.runid, data: action.payload }] : [...state.optics_plots_laser_render_data]
        }
    }

    if (action.type === GET_BEAD_LOSS_PLOTS_DATA_CSV) {       
        return {
            ...state,
            bead_loss_plots: state.bead_loss_plots.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.bead_loss_plots, { runid: action.runid, data: action.payload }] : [...state.bead_loss_plots]
        }
    }

    if (action.type === GET_BEAD_LOSS_PLOTS_DATA) {
        return { ...state, bead_loss_plots: action.payload };
    }

    if (action.type === GET_HISTOGRAM_BY_FLOWS_PLOTS_DATA) {       
        return {
            ...state,
            histogram_by_flow_plots_data: state.histogram_by_flow_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.histogram_by_flow_plots_data, { runid: action.runid, data: action.payload }] : [...state.histogram_by_flow_plots_data]
        }
    }

    if (action.type === SET_DENSITY_PLOTS_RENDER_DATA) {
        return { ...state, density_plots_render_data: action.payload };
    }

    if (action.type === SET_STATS_DENSITY_TABLE_DATA) {
        return { ...state, statsTableDensityData: action.payload };
    }

    if (action.type === GET_CLUMPINGS_TABLE_DATA) {
        return { ...state, clumpings_table_data: action.payload };
    }

    if (action.type === SET_CLUMPINGS_TABLE_RENDER_DATA) {
        return { ...state, clumpings_table_render_data: action.payload };
    }

    if (action.type === GET_HEALTH_DATA_TEMPERATURE_PLOTS) {        
        return {
            ...state,
            health_data_tmpr: state.health_data_tmpr.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.health_data_tmpr, { runid: action.runid, data: action.payload }] : [...state.health_data_tmpr]
        }
    }

    if (action.type === SET_HEALTH_DATA_IS_LOADING) {
        return { ...state, health_data_is_loading: action.payload };
    }

    if (action.type === SET_HEALTH_DATA_TEMPERATURE_RENDER_DATA) {        
        return {
            ...state,
            temperature_plot_render_data: state.temperature_plot_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.temperature_plot_render_data, { runid: action.runid, data: action.payload }] : [...state.temperature_plot_render_data]
        }
    }

    if (action.type === GET_HEALTH_DATA_HUMIDITY_PLOTS) {    
        return {
            ...state,
            health_data_humidity: state.health_data_humidity.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.health_data_humidity, { runid: action.runid, data: action.payload }] : [...state.health_data_humidity]
        }
    }

    if (action.type === SET_HEALTH_DATA_HUMIDITY_RENDER_DATA) {
        return {
            ...state,
            humidity_plot_render_data: state.humidity_plot_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.humidity_plot_render_data, { runid: action.runid, data: action.payload }] : [...state.humidity_plot_render_data]
        }
    }

    if (action.type === GET_COVERAGE_PLOTS) {
        return { ...state, coverage_plots: action.payload };
    }

    if (action.type === GET_PHASING_TABLE_DATA) {
        return { ...state, phasing_table_data: action.payload };
    }

    if (action.type === GET_PHASING_TABLE_RENDER_DATA) {
        return { ...state, phasing_table_render_data: action.payload };
    }

    if (action.type === GET_COVGCBIAS_TABLE_DATA) {
        return { ...state, covgcbias_table_data: action.payload };
    }

    if (action.type === GET_COVGCBIAS_TABLE_RENDER_DATA) {
        return { ...state, covgcbias_table_render_data: action.payload };
    }

    if (action.type === CHECK_VCREPORT_EXIST) {
        return { ...state, vcreport_exists: action.payload };
    }

    if (action.type === GET_VCREPORT_PLOTS) {
        return { ...state, vcreport_plots: action.payload };
    }

    if (action.type === GET_IMG_DEFORM_DATA) {
        return {
            ...state,
            img_deform_data: state.img_deform_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.img_deform_data, { runid: action.runid, data: action.payload }] : [...state.img_deform_data]
        }
    }

    if (action.type === SET_IMG_DEFORM_RENDER_DATA) {
        return {
            ...state,
            img_deform_render_data: state.img_deform_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.img_deform_render_data, { runid: action.runid, data: action.payload }] : [...state.img_deform_render_data]
        }
    }

    if (action.type === SET_IS_IMG_DEFORM_PLOTS_DATA_LOADING) {
        return { ...state, isImgDeformDataLoading: action.payload };
    }

    if (action.type === SET_SAM_REPORT_IS_LOADING) {
        return { ...state, is_sam_report_loading: action.payload };
    }

    if (action.type === SET_FWHM_PLOTS_IS_LOADING) {
        return { ...state, is_fwhm_plot_loading: action.payload };
    }

    if (action.type === GET_FWHM_PLOTS_DATA) {
        return {
            ...state,
            fwhm_plots_data: state.fwhm_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.fwhm_plots_data, { runid: action.runid, data: action.payload }] : [...state.fwhm_plots_data]
        }
    }

    if (action.type === GET_FWHM_PLOTS_CAMERA_DATA) {       
        return { ...state, fwhm_plots_camera_data: action.payload };
    }

    if (action.type === SET_TEMPLATE_REPORT_IS_LOADING) {
        return { ...state, is_template_report_loading: action.payload };
    }

    if (action.type === GET_TEMPLATE_REPORT) {
        return {
            ...state,
            template_report: state.template_report.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.template_report, { runid: action.runid, data: action.payload }] : [...state.template_report]
        }
    }

    
    if (action.type === SET_TEMPLATE_REPORT_EXIST) {
        return {
            ...state,
            is_template_report_exist: state.is_template_report_exist.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.is_template_report_exist, { runid: action.runid, data: action.payload }] : [...state.is_template_report_exist]
        }
    }

    if (action.type === SET_TEMPLATE_REPORT_RENDER_DATA) {
        return {
            ...state,
            template_report_render_data: state.template_report_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.template_report_render_data, { runid: action.runid, data: action.payload }] : [...state.template_report_render_data]
        }
    }

    if (action.type === SET_SUPPLEMENTARY_REPORT_IS_LOADING) {
        return { ...state, is_supplementary_report_loading: action.payload };
    }

    if (action.type === GET_SUPPLEMENTARY_REPORT) {
        return {
            ...state,
            supplementary_report: state.supplementary_report.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.supplementary_report, { runid: action.runid, data: action.payload }] : [...state.supplementary_report]
        }
    }

    if (action.type === SET_SUPPLEMENTARY_REPORT_RENDER_DATA) {
        return {
            ...state,
            supplementary_report_render_data: state.supplementary_report_render_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.supplementary_report_render_data, { runid: action.runid, data: action.payload }] : [...state.supplementary_report_render_data]
        }
    }

    if (action.type === SET_LATE_FLOW_METRICS_IS_LOADING) {
        return { ...state, is_late_flow_metrics_data_loading: action.payload };
    }

    if (action.type === GET_LATE_FLOW_METRICS_DATA) {
        return {
            ...state,
            late_flow_metrics_data: state.late_flow_metrics_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.late_flow_metrics_data, { runid: action.runid, data: action.payload }] : [...state.late_flow_metrics_data]
        }
    }

    if (action.type === SET_TOOL_NOISE_PLOTS_IS_LOADING) {
        return { ...state, is_tool_noise_plots_loading: action.payload };
    }

    if (action.type === GET_TOOL_NOISE_PLOTS) {
        return {
            ...state,
            tool_noise_plots: state.tool_noise_plots.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.tool_noise_plots, { runid: action.runid, data: action.payload }] : [...state.tool_noise_plots]
        }
    }

    if (action.type === SET_TOOL_NOISE_TABLE_DATA_IS_LOADING) {
        return { ...state, is_tool_noise_table_data_loading: action.payload };
    }

    if (action.type === GET_TOOL_NOISE_TABLE_DATA) {
        return {
            ...state,
            tool_noise_table_data: state.tool_noise_table_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.tool_noise_table_data, { runid: action.runid, data: action.payload }] : [...state.tool_noise_table_data]
        }
    }

    if (action.type === SET_CUSTOM_FILTERS) {
        return { ...state, custom_filters: action.payload };
    }

    if (action.type === SET_CUSTOM_FILTERS_APPLIED) {
        return { ...state, is_custom_filters_applied: action.payload };
    }

    if (action.type === SET_RUN_LIST_DOWNLOAD_DATA) {
        return { ...state, runlist_download_data: action.payload };
    }

    if (action.type === SET_RUN_LIST_AMP_DOWNLOAD_DATA) {
        return { ...state, runlist_amp_download_data: action.payload };
    }

    if (action.type === SET_ALL_RUN_LIST_DATA_SHOWN_BY_FLOW_NUM_100) {
        return { ...state, is_all_run_list_data_shown: action.payload };
    }

    if (action.type === SET_ANALYSIS_COMMENT_DATA_SAVED) {
        return { ...state, is_analysis_comments_data_saved: action.payload };
    }

    if (action.type === SET_DETAILS_DATA_SAVED) {
        return { ...state, is_details_data_saved: action.payload };
    }

    if (action.type === SET_ISAUTHENTICATED) {
        return { ...state, isAuthenticated: action.payload };
    }

    if (action.type === LOGOUT_CURRENT_USER) {
        return { ...state, user: undefined, isAuthenticated: false };
    }

    if (action.type === RESET_STATE) {
        return getInitialState();
    }

    if (action.type === SET_EDIT_MODE) {
        return { ...state, is_in_edit_mode: action.payload};
    }    

    if (action.type === SET_NUMBER_OF_CUSTOM_FILTERS_APPLIED) {
        return { ...state, number_of_custom_filters_applied: action.payload };
    }

    if (action.type === SET_SEARCH_TERM) {
        let value = [...state.search_term];
        const existingObjectIndex = value?.findIndex(item => item?.searchColumn === action.payload?.searchColumn);
        if (action.payload?.searchWords === null) {
            if (existingObjectIndex !== -1) {
                value.splice(existingObjectIndex, 1);
            }
        } else {
            if (existingObjectIndex !== -1 && action.payload?.searchWords !== null) value[existingObjectIndex] = action.payload;
            else value.push(action.payload);
        }
        return { ...state, search_term: value };
    }

    if (action.type === SET_SEARCH_TERM_AMP) {
        //return { ...state, search_term_amp: action.payload };
        let value = [...state.search_term_amp];
        const existingObjectIndex = value?.findIndex(item => item?.searchColumn === action.payload?.searchColumn);
        if (action.payload?.searchWords === null) {
            if (existingObjectIndex !== -1) {
                value.splice(existingObjectIndex, 1);
            }
        } else {
            if (existingObjectIndex !== -1 && action.payload?.searchWords !== null) value[existingObjectIndex] = action.payload;
            else value.push(action.payload);
        }
        return { ...state, search_term_amp: value };
    }
    
    if (action.type === IS_EDIT_ALLOWED) {
        return { ...state, is_edit_allowed: action.payload };
    }
    
    if (action.type === SET_ERROR_PLOT_IS_LOADING) {
        return { ...state, is_error_plot_loading: action.payload };
    }

    if (action.type === SET_READ_LENGTH_PLOT_IS_LOADING) {
        return { ...state, is_read_length_plot_loading: action.payload };
    }

    if (action.type === GET_READ_LENGTH_PLOTS) {        
        return {
            ...state,
            read_length_plots: state.read_length_plots.filter(f => f.runid === action.runid && f.camera === action.camera).length === 0 ? [...state.read_length_plots, { runid: action.runid, camera: action.camera, data: action.payload }] : [...state.read_length_plots]
        }        
    }

    if (action.type === SET_READ_LENGTH_PLOTS_BY_CAMERA_VALUE) {
        return { ...state, read_length_plots_by_camera_value: action.payload };
    }

    if (action.type === SET_ERROR_PLOTS_BY_CAMERA_VALUE) {
        return { ...state, erorr_plots_by_camera_value: action.payload };
    }

    if (action.type === GET_RSQ_PLOT) {        
        return {
            ...state,
            rsq_plots: state.rsq_plots.filter(f => f.runid === action.runid && f.camera === action.camera).length === 0 ? [...state.rsq_plots, { runid: action.runid, camera: action.camera, data: action.payload }] : [...state.rsq_plots]
        }        
    }

    if (action.type === SET_RSQ_PLOTS_BY_CAMERA_VALUE) {
        return { ...state, rsq_plots_by_camera_value: action.payload };
    }

    if (action.type === SET_RSQ_PLOT_IS_LOADING) {
        return { ...state, is_rsq_plot_loading: action.payload };
    }

    if (action.type === SET_BARCODE_COVERAGE_PLOTS_IS_LOADING) {
        return { ...state, is_barcode_coverage_plot_loading: action.payload };
    }

    if (action.type === GET_POLY_PLOTS) {
        return {
            ...state,
            poly_plots_data: state.poly_plots_data.filter(f => f.runid === action.runid && f.camera === action.camera).length === 0 ? [...state.poly_plots_data, { runid: action.runid, camera: action.camera, data: action.payload }] : [...state.poly_plots_data]
        }
    }

    if (action.type === GET_FWHM_PLOTS) {
        return {
            ...state,
            fwhm_plots_data: state.fwhm_plots_data.map(m => m.runid).indexOf(action.runid) === -1 ? [...state.fwhm_plots_data, { runid: action.runid, data: action.payload }] : [...state.fwhm_plots_data]
        }
    }

    if (action.type === RUN_SUMMARY_DENSITY_PLOT_BY_CAMERA_VALUE) {
        return { ...state, run_summary_density_plots_by_camera_value: action.payload };
    }  

    if (action.type === SET_RUN_SUMMARY_DENSITY_PLOT_RENDER_DATA) {        
        return {
            ...state,
            run_summary_density_plots_render_data: state.run_summary_density_plots_render_data.filter(f => f.runid === action.runid && f.camera === action.camera && f.flow === action.flow).length === 0 ? [...state.run_summary_density_plots_render_data, { runid: action.runid, camera: action.camera, flow: action.flow, data: action.payload }] : [...state.run_summary_density_plots_render_data]
        }        
    }

    if (action.type === GET_RUN_SUMMARY_DENSITY_PLOT_DATA) {        
        return {
            ...state,
            run_summary_density_plots_data: state.run_summary_density_plots_data.filter(f => f.runid === action.runid && f.flow === action.flow).length === 0 ? [...state.run_summary_density_plots_data, { runid: action.runid, flow: action.flow, data: action.payload }] : [...state.run_summary_density_plots_data]
        }        
    }

    if (action.type === SET_RUN_SUMMARY_DENSITY_PLOT_IS_LOADING) {
        return { ...state, run_summary_density_plots_is_loading: action.payload };
    }

    if (action.type === SET_VERSION) {
        return { ...state, version: action.payload };
    }

    if (action.type === SET_BEADS_FILTERING_PLOTS_IS_LOADING) {
        return { ...state, beads_filtering_plot_is_loading: action.payload };
    }

    if (action.type === READS_FILTERING_PLOT_BY_CAMERA_VALUE) {
        return { ...state, reads_filtering_plots_by_camera_value: action.payload };
    } 

    if (action.type === SET_FMETRICS_DATA_IS_LOADING) {
        return { ...state, is_fmetrics_table_data_loading: action.payload };
    }

    if (action.type === GET_COVERAGE_PLOTS_DATA) {        
        return {
            ...state,
            coverage_plots_data: state.coverage_plots_data.filter(f => f.runid === action.runid && f.flow === action.flow).length === 0 ? [...state.coverage_plots_data, { runid: action.runid, flow: action.flow, data: action.payload }] : [...state.coverage_plots_data]
        }        
    }

    if (action.type === GET_CHART_VIEW_PLOTS_DATA) {
        return { ...state, char_view_plots_data: action.payload };
    }

    if (action.type === SET_CHART_VIEW_PLOTS_DATA_IS_LOADING) {
        return { ...state, is_chart_view_plots_data_loading: action.payload };
    }

    if (action.type === GET_BASE_QUALITY_BER_PER_FLOW_PLOT) {
        return { ...state, base_quality_ber_per_flow_plot_data: action.payload };
    }

    if (action.type === SET_ALERT) {
        return Object.assign({}, state, {
            error: {
                ...state.error,
                errorMessage: action.payload.errorMessage,
                errorCode: action.payload.errorCode,
                errorType: action.payload.errorType
            }
        });
    }

    if (action.type === CLEAR_ALERT) {
        return Object.assign({}, state, {
            error: {
                ...state.error,
                errorMessage: action.payload.errorMessage,
                errorCode: action.payload.errorCode,
                errorType: action.payload.errorType
            }
        });
    }

    if (action.type === SET_IS_UNIFIED_ROW_SELECTED) {
        return { ...state, is_unified_row_selected: action.payload };
    }   
    
    if (action.type === GET_HOMO_INDEL_ERROR) {
        return { ...state, homo_indel_error: action.payload };
    }

    if (action.type === GET_BARCODE_DETAILS_READ_LENGTH_PLOT_DATA) {
        return { ...state, barcode_details_read_length_plot: action.payload };
    }    

    if (action.type === SET_POLY_PLOTS_BY_CAMERA_VALUE) {
        return { ...state, poly_plots_by_camera_value: action.payload };
    } 

    if (action.type === SET_POLY_PLOTS_IS_LOADING) {
        return { ...state, is_poly_plot_loading: action.payload };
    }

    if (action.type === ADD_PLOTLY_PLOT_TO_LIST) {
        return { 
            ...state,
            plotly_plots_list: [...state.plotly_plots_list, action.payload]
        }
    }

    if (action.type === GET_FWHM_CSV_DATA) {
        return { ...state, fwhm_csv_data: action.payload };
    }

    if (action.type === SET_JWT) {
        return { ...state, jwt: action.payload };
    }
    
    if (action.type === SET_BY_CAMERA_VALUE) {
        return { ...state, by_camera_value_global: action.payload };
    }

    if (action.type === SET_SHOW_FROZEN_HEADER) {
        return { ...state, show_frozen_header: action.payload };
    }

    if(action.type === SINGLE_VALID_RUN_DATA){
        return { ...state, validated_single_run: action.payload }
    }

    if(action.type === LOAD_VALID_AMP_DATA){
        return { ...state, validated_amplist_data: action.payload }
    }
    
    if (action.type === LOAD_VALID_DATA) {
        return { ...state, validated_runlist_data: action.payload };
    }

    if (action.type === LOAD_H5PLOT_VALID_DATA) {
        return { ...state, validated_h5plot_data: action.payload };
    }
    
    if (action.type === SET_VALID_DATA) {
        return { ...state, set_runlist_validated_data: action.payload };
    }

    if (action.type === SET_H5PLOTS_VALID_DATA) {
        return { ...state, set_h5plot_validated_data: action.payload };
    }

    if (action.type === IS_VALID_DATA_LOADING) {
        return { ...state, is_validated_data_loading: action.payload };
    }

    if (action.type === VALIDATION_STATUS) {
        return { ...state, validation_status: action.payload };
    }

    if (action.type === SET_JWT_EXP) {
        return { ...state, jwt_exp: action.payload };
    }    

    if (action.type === FWHM_VALID_RUN_DATA){
        return {...state, fwhm_validated_data: action.payload }
    }

    if (action.type === GET_RUN_CONFIGURATION_VALIDATED_DATA){
        return {...state, run_configuration_validated_data: action.payload }
    }

    if (action.type === GET_ERROR_PLOTS_VALIDATED){
        return {...state, error_plots_validated: action.payload}
    }

    if(action.type === GET_READS_FILTERING_PLOTS_VALIDATED){
        return {...state, reads_filtering_validated: action.payload}
    }

    if (action.type === GET_RSQ_PLOT_VALIDATED){
        return {...state, rsq_plots_validated: action.payload }
    }

    if (action.type === GET_BARCODE_STATISTICS_VALIDATED){
        return {...state, barcode_statistics_validated: action.payload }
    }

    if (action.type === GET_CLUMPINGS_VALIDATED){
        return {...state, clumpings_validated: action.payload}
    }

    if (action.type === GET_PHASING_VALIDATED_DATA){
        return {...state, phasing_validated: action.payload}
    }

    if (action.type === GET_LINEARITY_VALIDATED){
        return {...state, linearity_validated: action.payload}
    }

    if(action.type === GET_BEADS_FILTERING_VALIDATED){
        return {...state, beads_filtered_validated: action.payload}
    }

    if (action.type === GET_BER_TABLE_VALIDATED){
        return {...state, ber_validated: action.payload}
    }

    if (action.type === GET_BER_HMER_TABLE_VALIDATED){
        return {...state, ber_hmer_validated: action.payload}
    }

    if (action.type === GET_RLQ_TABLE_VALIDATED){
        return {...state, rlq_validated: action.payload}
    }

    if(action.type === GET_FMETRICS_TABLE_VALIDATED){
        return {...state, fmetrics_validated: action.payload}
    }

    if (action.type === GET_Q_TABLE_VALIDATED){
        return {...state, q_validated: action.payload}
    }

    if (action.type === GET_VALIDATED_READ_LENGTH_PLOTS){
        return {...state, read_length_validated: action.payload}
    }

    if (action.type === GET_SIGNAL_DATA_VALIDATED){
        return { ...state, signal_linearity_validated: action.payload }
    }
    
    if (action.type === GET_RUNINFO_DATA) {
        return { ...state, run_info: action.payload }
    }

    if (action.type === SET_CUSTOM_STORAGE){
        const newState = { ...state, custom_storage: action.payload };
        localStorage.setItem('customStorageValues', action.payload);
        return newState;
        //return { ...state, custom_storage: action.payload }
    }

    if (action.type === GET_DISKSPACE) {
        return { ...state, diskspace: action.payload }
    }

    if (action.type === SET_ALERT_STORAGE_RUN) {
        return { ...state, alert_storage_status: action.payload }
    }

    if (action.type === DELETE_RUN_REQUEST_STATUS) {
        return { ...state, delete_run_request_status: action.payload }
    }

    if (action.type === CALCULATED_CUSTOM_STORAGE_SPACE) {
        return { ...state, calculated_storage_free_space: action.payload }
    }

    if (action.type === ACTIVE_BARCODE_TABLE_BUTTON) {
        const newState = { ...state, active_barcode_table_button: action.payload };
        localStorage.setItem('active_barcode_table_button', action.payload);
        return newState;
        //return { ...state, active_barcode_table_button: action.payload }
    }

    if(action.type === ADD_PLOTLY_URI_LIST){
        return {
          ...state,
          plotly_uri_list: [...state.plotly_uri_list, action.payload],
        };
    }

    if (action.type === ADD_RUN_DETAILS_TABLE_PDF) {     
        const {runid, data} = action.payload 
        return {
            ...state,
            run_details_table_pdf: [{runid, data}]  //state.run_details_table_pdf.filter(m => m.runid).length === 0 ? [...state.run_details_table_pdf, { runid: action.runid, data: action.payload }] : [...state.run_details_table_pdf]
        }
    }  

    if(action.type === ADD_BEADS_FFILTER_TABLE_PDF){
        return { ...state, beads_filter_table_pdf: action.payload }
    }
    
    if (action.type === TIME_ZONE_SELECTED){
        const newState = { ...state, time_zone_selected: action.payload };
        localStorage.setItem('time_zone', action.payload);
        return newState;
        //return { ...state, time_zone_selected: action.payload }
    }

    if(action.type === SET_IS_IMPORT_FIELD_LOADING){
        return { ...state, is_import_field_loading: action.payload }
    }
    
    if (action.type === POPULATE_RUNIDS){
        return { ...state, populate_runids: action.payload }
    }

    if (action.type === GET_PPMSEQ_REPORT_LIST){
        return { ...state, ppmSeqReportList: action.payload }
    }

    if (action.type === SET_COLUMN_TYPE){
        return { ...state, columnType: action.payload }
    }

    if (action.type === SET_BARCODE_COVERAGE_BARCODE){
        return { ...state, barcode_coverage_selected_barcode: action.payload }
    }  
    
    if (action.type === SET_EXPANDED_RUNID) {
        const { runId, expandedType } = action.payload;
        return {
            ...state,
            expandedRunIds: {
                ...state.expandedRunIds,
                [runId]: expandedType
            }
        };
    }

    if (action.type === CLEAR_EXPANDED_RUNID) {
        const { [action.payload]: _, ...remainingExpandedRunIds } = state.expandedRunIds;
        return {
            ...state,
            expandedRunIds: remainingExpandedRunIds
        };
    }

    return state;
};

export default rootReducer;