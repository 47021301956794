import React, {useEffect} from 'react';
import StatusCheckItem from './statuscheckitem';
import { useSelector } from "react-redux";

const SearchStatusGroup = props => {
    const { group, updateState, title, data, op} = props;
    const [operation, setOperation] = React.useState({group: group, data: data, op: op ? op : 'a',});
    
    const state = useSelector(state => state);

    useEffect(() => {
        if (state.is_custom_filters_applied === false) setOperation({ ...operation, data });   
        // eslint-disable-next-line react-hooks/exhaustive-deps             
    }, [state.is_custom_filters_applied]);

    useEffect(() => {
        if (updateState && (operation.c || operation.d || operation.f || operation.l || operation.p || operation.op)) updateState(group, operation);      
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [operation]);

    const statusItems = [
        { name: "d", label: "Didn't start" },
        { name: "p", label: "In progress" },
        { name: "c", label: "Complete" },
        { name: "f", label: "Failed" },
        { name: "l", label: "Canceled" },
        { name: "q", label: "Queued" }
    ]    

    const handleOptionChange = (e) => {
        try {
            let name = e.target.name;
            let checked = e.target.checked;
            let o = operation;
            o.data[name] = checked;
            setOperation({ ...o });
        }
        catch { }
    }

    return (
        <div className="status-group">
            <div>{title}</div>
            <div className="status-items">
                {statusItems.map((item, i) =>
                    <StatusCheckItem name={item.name} key={item.name}
                        label={item.label} checked={operation.data[item.name] || false}
                        handleOptionChange={handleOptionChange} disabled={op === 'a'} type={op === 'AND' ? 'radio' : 'checkbox'} />
                )}
            </div>
        </div>
    )
}

export default SearchStatusGroup;
