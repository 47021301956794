import React, { useState, useEffect, } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Container, Row, Col, } from "react-bootstrap";
import * as Utils from '../../utils/utils';
import HorizontalSpinner from '../spinner/horizontalspinner';
import { getAMPDataAsync, setCurrentAMPRunData, getAMPPlotsDataAsync } from "../actions/index";
import AMPRunHealth from '../amprunhealth/amprunhealth';
import * as RenderAmpPlots from '../../plots/ampplots/ampplots';
import RenderTable from '../amptable/table';

const AMP = () => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [ampTableData, setAmpTableData] = useState([]);
    const [ampPlot1Data, setAmpPlot1Data] = useState({});
    const [ampPlot2Data, setAmpPlot2Data] = useState({});


    useEffect(() => {        
        if (state.amplist.length === 0) {
            dispatch(getAMPDataAsync(state.jwt));
        }   
        // eslint-disable-next-line react-hooks/exhaustive-deps    
    }, []);

    useEffect(() => {
        if (state.runlist_searched_amp !== undefined && state.runlist_searched_amp.length > 0) {
            const ampListVar = state.runlist_searched_amp.filter(f => f.start !== undefined && f.start !== null).sort(function(a,b){       
                return new Date(b.start) - new Date(a.start);
              }).map(m => {
                return {
                    ...m,                    
                    start: Utils.getStartDate(m.start)
                };
            });
            setAmpTableData(ampListVar);           
        }     
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.runlist_searched_amp]);

    useEffect(() => {
        try {
            let exists = state.amp_plots_data.filter(f => f.runid === state.ampRunId);
            if (exists.length > 0) {
                let data = exists[0].data;
                if (data.length > 0) {
                    if (data[0].Name) {
                        let amp1 = RenderAmpPlots.RenderEPCRChart(data, false);
                        setAmpPlot1Data(amp1);
                        let amp2 = RenderAmpPlots.RenderEPCRChartHealth(data);
                        setAmpPlot2Data(amp2);
                    } else if (data[0].Key) {
                        let amp1 = RenderAmpPlots.RenderEPCRChartHealthAllTemperatures(data);
                        setAmpPlot1Data(amp1);
                        let amp2 = RenderAmpPlots.RenderEPCRChartHealthAllPressures(data);
                        setAmpPlot2Data(amp2);
                    } 
                }
                else {
                    setAmpPlot1Data(undefined);
                    setAmpPlot2Data(undefined);
                }
            }
            else if (state.ampRunId) {
                dispatch(getAMPPlotsDataAsync(state.ampRunId, state.jwt));
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.ampRunId]);

    useEffect(() => {
        try {
            let exists = state.amp_plots_data.filter(f => f.runid === state.ampRunId);
            if (exists.length > 0) {
                let data = exists[0].data;
                if (data.length > 0) {
                    if (data[0].Name) {
                        let amp1 = RenderAmpPlots.RenderEPCRChart(data, false);
                        setAmpPlot1Data(amp1);
                        let amp2 = RenderAmpPlots.RenderEPCRChartHealth(data);
                        setAmpPlot2Data(amp2);
                    } else if (data[0].Key) {
                        let amp1 = RenderAmpPlots.RenderEPCRChartHealthAllTemperatures(data);
                        setAmpPlot1Data(amp1);
                        let amp2 = RenderAmpPlots.RenderEPCRChartHealthAllPressures(data);
                        setAmpPlot2Data(amp2);
                    }                    
                }
                else {
                    setAmpPlot1Data(undefined);
                    setAmpPlot2Data(undefined);
                }
            }
            else {
                setAmpPlot1Data(undefined);
                setAmpPlot2Data(undefined);
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.amp_plots_data]);

    useEffect(() => {
        if (state.amplist && state.amplist.length > 0 && state.runlist_searched_amp.length === 0) {
            const ampListVar = state.amplist.filter(f => f.start !== undefined && f.start !== null).sort(function(a,b){       
                return new Date(b.start) - new Date(a.start);
              }).map(m => {
                return {
                    ...m,                   
                    start: Utils.getStartDate(m.start),
                    reagent: Utils.generateAMPReagentsString(m)
                };
            });
            setAmpTableData(ampListVar);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.amplist]);   

    const handleTabsSelect = () => {
        dispatch(setCurrentAMPRunData(state.amplist.filter(f => f.runid === state.ampRunId)));
    }

    const handleClearAdvancedSearch = e => {
        try {
            dispatch(getAMPDataAsync(state.jwt));
        }
        catch { }
    }

    return (
        <Container fluid>
            <Row>
                <Col lg={12} style={{ marginTop: 15, height: 5 }}>
                    {state.is_amp_data_loading ? <HorizontalSpinner /> : null}
                </Col>
                <Col lg={12} style={{ marginTop: 15 }}>
                    <RenderTable mainTableData={ampTableData} handleTabsSelect={handleTabsSelect} handleClearAdvancedSearch={handleClearAdvancedSearch} />                  
                </Col>
            </Row>
            <Row>
                <Col lg={12} style={{ marginTop: 15 }}>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="seq" role="tabpanel" aria-labelledby="seq-tab">
                            {state.ampRunId !== undefined && state.ampRunId !== null ?
                                <React.Fragment>
                                    {state.user && state.user.roles.filter(f => f.name === Utils.NEXUS_ROLES.CUSTOMER).length === 0 && <Tabs fill defaultActiveKey="amp_run_health" id="gridtabs" style={{ marginTop: 15 }} onSelect={handleTabsSelect}>
                                        <Tab eventKey="amp_run_health" title="Run Health">
                                            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                                                <AMPRunHealth ampPlot1Data={ampPlot1Data} ampPlot2Data={ampPlot2Data} />
                                            </Tab.Container>
                                        </Tab>
                                    </Tabs>}
                                </React.Fragment>
                                : null
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >
    )
};

export default AMP;