import Plotly, { register } from 'plotly.js/lib/core';
import Scatter from 'plotly.js/lib/scatter';
import Bar from 'plotly.js/lib/bar';
import Histogram from 'plotly.js/lib/histogram';
import Heatmap from 'plotly.js/lib/heatmap';
import Scatterpolargl from 'plotly.js/lib/scatterpolargl';
import Box from 'plotly.js/lib/box';

// Load in the trace types
register([Scatter, Bar, Histogram, Heatmap, Scatterpolargl, Box ]);

export default Plotly;
