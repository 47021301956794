import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import InfoIcon from '@material-ui/icons/Info';
import Divider from '@material-ui/core/Divider';
import useOnClickOutside from '../../utils/hooks/useOnClickOutside';
import { useDispatch, useSelector } from "react-redux";
import { logOutCurrentUser, resetState, setJWT, setAlertStorageRun } from '../actions/index';
import ArticleIcon from '@mui/icons-material/Article';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import StorageIcon from '@mui/icons-material/Storage';
import Badge from "@mui/material/Badge";
import PublicIcon from '@mui/icons-material/Public';
import * as Utils from '../../utils/utils';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,       
    },
    customWidth: {
        '& div': {           
            width: '150px',
        }
    },
    customBadge: {
        background: 'red'
    }
}));

const ProfileMenu = (props) => {  
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [alertStatus, setAlertStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const { onAboutClick, onStorageClick, onTimeZoneClick } = props;
    const dispatch = useDispatch();    

    const redirect = () => window.location.href = `${process.env.REACT_APP_API_URL_AUTH}/logout/?from=nexus`;

    const ref = useRef();
    useOnClickOutside(ref, () => handleMenuClose());

    const current_user = useSelector(state => state.user);
    const alert = useSelector(state => state.alert_storage_status);
    const deletionStat = useSelector(state => state.delete_run_request_status);
    const time_zone_selected = useSelector(state => state.time_zone_selected);

    useEffect(() => {
        if(alert) setAlertStatus(alert);
        if(deletionStat !== null) setDeleteStatus(deletionStat);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alert, deletionStat]);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuAbout = (event) => {
        onAboutClick(event);
        setAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuLogOut = async () => {
        dispatch(setJWT(undefined));
        dispatch(logOutCurrentUser());
        dispatch(resetState());
        setAnchorEl(null);
        redirect();
    };    

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
        newWindow.location.reload();
    }

    const onClickUrl = (url) => {
        return () => openInNewTab(url);
    }

    const handleMenuStorage = (event) => {
        onStorageClick(event);
        setAnchorEl(null);
    };

    const handleMenuTimeZone = (event) => {
        onTimeZoneClick(event);
        setAnchorEl(null);
    };

    const alertProfileMenu = (alert, user) => {
        try {
            if (alert === true && user && user.roles !== undefined) {
                return (
                    <Tooltip title="Low Available Storage" followCursor>
                        <Badge
                            badgeContent="!"
                            sx={{
                                "& .MuiBadge-badge": {
                                  color: "white",
                                  backgroundColor: "#A91B0D"
                                }
                              }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >  
                            <IconButton
                                onClick={handleProfileMenuOpen}                        
                                size="small"                                             
                                sx={{ ml: 2 }}
                                aria-controls={Boolean(anchorEl) ? "account-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                            >
                                <Avatar sx={user != undefined ? { width: 32, height: 32, bgcolor: 'green'} : { width: 32, height: 32}} />
                            </IconButton>
                        </Badge>
                    </Tooltip>
                );
            } else {
                return (
                    <Tooltip title="Settings">
                        <IconButton
                            onClick={handleProfileMenuOpen}                        
                            size="small"                                             
                            sx={{ ml: 2 }}
                            aria-controls={Boolean(anchorEl) ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                        >
                            <Avatar sx={user != undefined ? { width: 32, height: 32, bgcolor: 'green'} : { width: 32, height: 32}} />
                        </IconButton>
                    </Tooltip>
                );
            }
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    
    const renderMenu = (
        <>
            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <div style={{display: 'flex', flexDirection: 'column' }}>
                    <Typography sx={{ minWidth: 200, marginRight: 3,  }}>{current_user != undefined ? current_user.firstName !== undefined && current_user.lastName !== undefined ? `Hello, ${current_user.firstName} ${current_user.lastName}` : current_user.email : ''}</Typography>
                    <Typography sx={{ minWidth: 200, marginRight: 3,  }}>Time Zone: {time_zone_selected}</Typography>
                </div>
                {alertProfileMenu(alert, current_user)}
                {/* <Tooltip title="Settings">
                        <IconButton
                            onClick={handleProfileMenuOpen}                        
                            size="small"                                             
                            sx={{ ml: 2 }}
                            aria-controls={Boolean(anchorEl) ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                        >
                            <Avatar sx={current_user != undefined ? { width: 32, height: 32, bgcolor: 'green'} : { width: 32, height: 32}} />
                        </IconButton>
                    </Tooltip> */}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="settings-menu"
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,                        
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0
                        },
                    }
                }}                
                transformOrigin={{ horizontal: "right", vertical: "top" }}
            > 
               <MenuItem onClick={handleMenuAbout}><ListItemIcon><InfoIcon style={{ marginRight: 10 }} fontSize="small" /> About</ListItemIcon></MenuItem>
                <MenuItem onClick={onClickUrl('Nexus233_UserManual.htm')}><ListItemIcon><ArticleIcon style={{ marginRight: 10 }} fontSize="small" /> Docs</ListItemIcon></MenuItem>
                {current_user !== undefined && current_user.roles && current_user.roles.filter(f => f.name === Utils.NEXUS_ROLES.ADMINISTRATORS || f.name === Utils.NEXUS_ROLES.SUPER_USER).length > 0 && (
                                               <MenuItem key="storage" onClick={handleMenuStorage}>
                                               <ListItemIcon>
                                                   <div style={{ display: 'flex', alignItems: 'center' }}>
                                                       <StorageIcon style={{ marginRight: 5 }} fontSize="small" /> Storage
                                                   </div>
                                               </ListItemIcon>
                                           </MenuItem>
                )}
                <MenuItem onClick={handleMenuTimeZone}><ListItemIcon><PublicIcon style={{ marginRight: 10 }} fontSize="small" />TimeZone</ListItemIcon></MenuItem>
                <Divider />
                <MenuItem onClick={handleMenuLogOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>       
    );

    return (
        <>
            <div className={classes.grow}> 
                {renderMenu}
            </div>
        </>
    );
}

export default ProfileMenu;