import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getChartViewPlotsData, } from "../actions/index";
import { Col, Row } from 'react-bootstrap';
import RenderPlot from '../plots/renderplot';
import * as RenderChartViewPlots from '../../plots/chartviewplots/chartviewplots';
import Button from '@mui/material/Button';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { makeStyles } from '@material-ui/core/styles';

const ChartView = () => {
    const useStyles = makeStyles((theme) => ({
        desktopColumn: {
            "@media (max-width:590px)": {
                marginBottom: '20px !important'
            },
        }
    }));

    const classes = useStyles();
    var date = new Date();
    var prev3M = new Date(date.getFullYear(), date.getMonth() - 3, date.getDate());
    const [chartViewData, setChartViewData] = React.useState([]);
    const [dateFrom, setDateFrom] = React.useState(prev3M);
    const [dateTo, setDateTo] = React.useState(date);   

    const state = useSelector(state => state);
    const dispatch = useDispatch();
    
    useEffect(() => {
        try {
            if (dateFrom !== undefined && dateTo !== undefined) dispatch(getChartViewPlotsData(new Date(dateFrom).toLocaleDateString("en-US"), new Date(dateTo).toLocaleDateString("en-US"), state.jwt));            
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        try {
            if (state.char_view_plots_data !== undefined) {
                let exists = state.char_view_plots_data[0];
                if(exists?.length > 0){
                    var plotsData = RenderChartViewPlots.DrawChartViewPlots(exists);
                    setChartViewData(plotsData);
                }
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.char_view_plots_data]);

    const handleDateFromChange = value => {
        setDateFrom(value);
    }

    const handleDateToChange = value => {
        setDateTo(value);
    }

    const handleSubmitClick = e => {
        if (dateFrom !== undefined && dateTo !== undefined) dispatch(getChartViewPlotsData(new Date(dateFrom).toLocaleDateString("en-US"), new Date(dateTo).toLocaleDateString("en-US"), state.jwt));        
    } 

    return (
        <Row>
            {chartViewData !== undefined &&
                <Col lg={12} style={{ marginTop: 30, marginBottom: 30, }}>
                    <Row style={{ justifyContent: 'center', display: 'flex', margin: '10px'}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Col lg={1}>
                                <DesktopDatePicker
                                    className={classes.desktopColumn}
                                    label="Date From:"
                                    inputFormat="MM/DD/YYYY"
                                    value={dateFrom}
                                    name="from"
                                    onChange={handleDateFromChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Col>
                            <Col lg={1}>
                                <DesktopDatePicker
                                    className={classes.desktopColumn}
                                    label="Date To:"
                                    inputFormat="MM/DD/YYYY"
                                    value={dateTo}
                                    name="to"
                                    onChange={handleDateToChange}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </Col>
                        </LocalizationProvider>
                    </Row>                 
                    <Row className="text-center">
                        <Col lg={12} style={{ justifyContent: 'center', display: 'flex', marginTop: 15, marginLeft: 5, }}>
                            <Button className="advanced-filter-btn" color="primary" size="large" startIcon={<DoneOutlineIcon id="DoneIcon"/>} style={{ height: '93%', width: '16%', color: 'white', float: 'right', marginRight: 10, boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }} onClick={handleSubmitClick}>Show</Button>
                        </Col>
                    </Row>
                </Col>
            }
            {chartViewData !== undefined && chartViewData.Aligned_Bases !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView1" data={chartViewData.Aligned_Bases && chartViewData.Aligned_Bases} layout={chartViewData.layoutAligned_Bases && chartViewData.layoutAligned_Bases} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.Indel_Rate !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView2" data={chartViewData.Indel_Rate && chartViewData.Indel_Rate} layout={chartViewData.layoutIndel_Rate && chartViewData.layoutIndel_Rate} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.Lag !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView4" data={chartViewData.Lag && chartViewData.Lag} layout={chartViewData.layoutLag && chartViewData.layoutLag} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.Lead !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView5" data={chartViewData.Lead && chartViewData.Lead} layout={chartViewData.layoutLead && chartViewData.layoutLead} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.Droop !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView6" data={chartViewData.Droop && chartViewData.Droop} layout={chartViewData.layoutDroop && chartViewData.layoutDroop} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.Cyc50 !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView7" data={chartViewData.Cyc50 && chartViewData.Cyc50} layout={chartViewData.layoutCyc50 && chartViewData.layoutCyc50} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.F95Min !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView8" data={chartViewData.F95Min && chartViewData.F95Min} layout={chartViewData.layoutF95Min && chartViewData.layoutF95Min} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.RLQ25 !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView9" data={chartViewData.RLQ25 && chartViewData.RLQ25} layout={chartViewData.layoutRLQ25 && chartViewData.layoutRLQ25} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
            {chartViewData !== undefined && chartViewData.BER80 !== undefined &&
                <Col lg={12}>
                    <RenderPlot id="chartView10" data={chartViewData.BER80 && chartViewData.BER80} layout={chartViewData.layoutBER80 && chartViewData.layoutBER80} config={chartViewData.config && chartViewData.config} isLoading={state.is_chart_view_plots_loading} type={'rounded'} width={'100%'} height={430} />
                </Col>
            }
        </Row>
    )
};

export default ChartView;