import React from "react";

const Redirect = () => {
    const redirect = () => window.location.href = `${process.env.REACT_APP_API_URL_AUTH}/signin/?destination=nexus`;

    React.useEffect(() => {
        redirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<></>);
};

export default Redirect;