import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { setShowFrozenHeader } from "../actions/index";
import { useDispatch } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Card from 'react-bootstrap/Card';
import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from '@material-ui/core';

const ManageColumns = (props) => {
    const { show, handleCloseModal, getToggleHideAllColumnsProps, allColumns, visibleColumns, localStorageFileName, onReset } = props;
    const [groupedColumns, setGroupedColumns] = React.useState([]);
    const [checked, setChecked] = React.useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        try {
            if (allColumns && allColumns.length > 0) {
                let result = allColumns.reduce(function (r, a) {
                    if (a.parent !== undefined && a.parent.Header !== undefined) {
                        r[a.parent.Header] = r[a.parent.Header] || [];
                        r[a.parent.Header].push(a);
                    }
                    return r;
                }, Object.create(null));
                setGroupedColumns(result);
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allColumns]);

    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
            const defaultRef = React.useRef()
            const resolvedRef = ref || defaultRef
            React.useEffect(() => {
                resolvedRef.current.indeterminate = indeterminate
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [resolvedRef, indeterminate])

            return <FormControlLabel control={<Checkbox ref={resolvedRef} {...rest} />} />
        }
    )

    const handleSave = () => {
        try {
            let toSave = allColumns.filter(function (el) {
                return visibleColumns.map(m => m.id).includes(el.id);
            });
            localStorage.removeItem(localStorageFileName);
            localStorage.setItem(localStorageFileName, JSON.stringify(toSave.map(m => m.id)));
        }
        catch { }
    }

    const handleReset = () => {
        onReset();
    }

    const handleApply = () => {
        handleSave();
        handleCloseModal();
    }

    const handleClose = () => {
        handleCloseModal();
    }

    useEffect(() => {
        try {
            let isHidden = JSON.parse(localStorage.getItem('user_config'));
            if (isHidden !== undefined && isHidden !== null && isHidden.SHOW_FROZEN_HEADER !== undefined) {
                setChecked(isHidden.SHOW_FROZEN_HEADER);
                dispatch(setShowFrozenHeader(isHidden.SHOW_FROZEN_HEADER));
            }
            else if (isHidden === undefined || isHidden === null) {
                setChecked(true);
                dispatch(setShowFrozenHeader(true));
            }
            else {
                setChecked(false);
                dispatch(setShowFrozenHeader(false));
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        try {
            setChecked(event.target.checked);
            dispatch(setShowFrozenHeader(event.target.checked));
            localStorage.removeItem('user_config');
            localStorage.setItem('user_config', JSON.stringify({ SHOW_FROZEN_HEADER: event.target.checked }));
        }
        catch { }
    };

    const getColumnLabelName = column => {
        if (column && column.Header === 'VER#') return 'Reanalysis Version (VER#)'
        else if (column && column.id === 'expander') return "Expand/Collapse"
        else if (column) return column.Header 
        else return ''
    }

    return (
        <Modal show={show}
            onHide={handleClose}
            centered dialogClassName="nxs-modal modal-90w"
            backdrop="static" keyboard="false" >
            <Modal.Header className="border-0" style={{ marginLeft: 'auto', marginRight: 0 }}>
                <IconButton onClick={handleClose} 
                    style={{
                    fontSize: '0.1rem',
                    padding: '0.1rem',
                }}>
                    <CloseIcon />
                </IconButton>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                <div>
                    <div style={{ marginLeft: 15 }}>
                        <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} /> Toggle All
                    </div>
                    <div className="row">
                        {Object.entries(groupedColumns).map(([key, value]) =>
                            <div className="col-lg-6" key={key}>
                                <Card style={{ margin: 10, minHeight: 60, }}>
                                    <Card.Body>
                                        <Card.Title>{key}</Card.Title>
                                        <Card.Text>
                                            {value.map((m, i) => <FormControlLabel key={i} control={<Checkbox {...m.getToggleHiddenProps()} name={m.id} color="primary" />} label={getColumnLabelName(m)} />)}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Card style={{ margin: 10, minHeight: 60, }}>
                                <Card.Body>
                                    <Card.Title>User Settings</Card.Title>
                                    <Card.Text>
                                        <FormControlLabel
                                            label="Show frozen header"
                                            control={
                                                <Checkbox
                                                    checked={checked}
                                                    onChange={handleChange}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                        />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="nxs-button mr-auto">
                    <Button onClick={handleReset} style={{ boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>Default</Button>
                </div>
                <div className="nxs-button last">
                    <Button onClick={handleApply} style={{ boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)' }}>Apply</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ManageColumns;