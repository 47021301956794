import React, { useEffect } from 'react';
import Skeleton from '@mui/material/Skeleton';
import ShowAlert from '../showalert/showalert';

const SkeletonLoader = props => {
    const [isPlotLoaded, setPlotLoaded] = React.useState(false);

    const { noData, isLoading, type } = props;

    useEffect(() => {
        if (isLoading) {
            setPlotLoaded(false);
        }
        else setPlotLoaded(true);    
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [isLoading]);  
    
    useEffect(() => {
        if (noData) {
            setPlotLoaded(true);
        }     
        // eslint-disable-next-line react-hooks/exhaustive-deps      
    }, [noData]);

    return (
        !isPlotLoaded ?
            <>
                <Skeleton variant={type || 'rounded'} width="100%" style={{ height: 100, marginTop: 15, }}/>
                <Skeleton variant={type || 'rounded'} width="100%" style={{ height: 100,  }} />
                <Skeleton variant={type || 'rounded'} width="100%" style={{ height: 100,  }} />
            </> : noData === true && <ShowAlert type={'warning'} text='No data' />         
    )
};

export default SkeletonLoader;
