import React, { useEffect } from 'react';
import { ListGroup, Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RenderPlot from '../plots/renderplot';
import LoadingSpinner from '../spinner/spinner';
import ShowAlert from '../showalert/showalert';
import * as RenderCirclePlots from '../../plots/circleplots/circleplots';
import { setHistogramPlotsByFlowRenderData, setStatsTableDataDensity, setIsHistogramPlotsLoading } from "../actions/index";
import * as Utils from '../../utils/utils';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import RangeSliderCommon from '../slider/slidercommon';
import Box from '@mui/material/Box';

const Density = (props) => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const [minDensitySpinnerValue, setMinDensitySpinnerValue] = React.useState(state.spinnerDensity.min);
    const [maxDensitySpinnerValue, setMaxDensitySpinnerValue] = React.useState(state.spinnerDensity.max);
    const [minDensitySpinnerDensityTabValue, setMinDensitySpinnerDensityTabValue] = React.useState(0);
    const [maxDensitySpinnerDensityTabValue, setMaxDensitySpinnerDensityTabValue] = React.useState(20000);
    const [densityPlotsData, setDensityPlotsData] = React.useState([]);
    const [densityStatsTableData, setDensityStatsTableData] = React.useState([]);
    const [expandedIds, setExpandedIds] = React.useState(['d1', 'd2', 'd3', 'd4']);

    const handleExpandClick = id => {
        setExpandedIds(expandedIds.includes(id) ? [...expandedIds.filter(item => item !== id)] : [...expandedIds, id]);
    }

    useEffect(() => {
        try {
            let exists = state.histogram_by_flow_plots_data.filter(f => f.runid === state.runId);
            let existsTileArea = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
            if (exists.length && existsTileArea.length > 0 && existsTileArea[0].data.TileArea) {
                let data = exists[0].data;
                let tileArea = existsTileArea[0].data;
                let res = data.map(m => {
                    return {
                        'beadsPerTile': m.BeadsPerTile,
                        'beadsPerTile_ForSignals_250GL': m.BeadsPerTile_ForSignals_250GL,
                        'flow': m.Flow,
                        'tileID': m.TileID,
                        'tileOffset': m.TileOffset,
                        'ring': m.Ring,
                        'radius': m.Radius,
                        'theta': m.Theta,
                        'mean_Sig': m.Mean_Sig,
                        'stdDev_Sig': m.StdDev_Sig,
                        'mean_SigStd': m.Mean_SigStd,
                        'count_beads_sig_gt_threshold': m.Count_beads_sig_gt_threshold,
                    }
                });
                data.data = res;

                let arraySumDensityRaw = Utils.unpackIntByTileArea(data.data, 'count_beads_sig_gt_threshold', tileArea.TileArea);
                let arraySumDensity = arraySumDensityRaw.reduce(Utils.getSum);
                let arrayCountDensity = data.data.length;
                let meanDensity = arraySumDensity / arrayCountDensity;
                let sigmaDensity = arraySumDensityRaw.stanDeviate();
                let sigmaValDensity = 3 * parseInt(sigmaDensity);
                let minDensityValCalc = parseInt(meanDensity) - sigmaValDensity;
                let maxDensityValCalc = parseInt(meanDensity) + sigmaValDensity;
                //dispatch(setSpinnerDensity({ min: minDensityValCalc, max: maxDensityValCalc }));
                setMinDensitySpinnerValue(minDensityValCalc);
                setMaxDensitySpinnerValue(maxDensityValCalc);

                reRenderCirclePlotsDensity(minDensitySpinnerDensityTabValue, maxDensitySpinnerDensityTabValue);
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.histogram_by_flow_plots_data, state.tileArea]);

    const reRenderCirclePlotsDensity = (minDensityVal, maxDensityVal) => {
        let exists = state.histogram_by_flow_plots_data.filter(f => f.runid === state.runId);
        if (exists.length > 0 && exists[0].data.data != undefined) {
            let flowsDistinct = [...new Set(exists[0].data.data.map(item => item.flow))];
            if (flowsDistinct != undefined) {
                if (flowsDistinct.length > 0) {
                    let circles = [];
                    let statsTables = [];
                    let tl = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
                    flowsDistinct.forEach((flow, i) => {
                        circles.push({ flow: flow, data: RenderCirclePlots.RenderCircleChartsByFlow(exists[0].data.data.filter(f => f.flow === flow), 'radius', 'theta', 'beadsPerTile', flow, minDensityVal, maxDensityVal, null, null, null, null, tl != undefined && tl.length > 0 ? tl[0].data.TileArea : undefined, tl != undefined && tl.length > 0 ? tl[0].data.Crop : undefined,
                            tl != undefined && tl.length > 0 ? tl[0].data.PixSize : undefined, tl != undefined && tl.length > 0 ? tl[0].data.TileSizeX : undefined, tl != undefined && tl.length > 0 ? tl[0].data.TileSizeY : undefined ) });
                        statsTables.push(getStatValues(exists[0].data.data.filter(f => f.flow === flow), flow));
                    });
                    dispatch(setHistogramPlotsByFlowRenderData(circles, state.runId));
                    setDensityPlotsData(circles);
                    dispatch(setStatsTableDataDensity(statsTables));
                    setDensityStatsTableData(statsTables);
                }
            }

            dispatch(setIsHistogramPlotsLoading(false));
        }
    };

    const onMinDensitySpinnerDensityTabChange = e => {
        setMinDensitySpinnerDensityTabValue(e.target.value);
    };

    const onMaxDensitySpinnerDensityTabChange = e => {
        setMaxDensitySpinnerDensityTabValue(e.target.value);
    };

    const onMinDensitySpinnerDensityTabChangeSlider = val => {
        setMinDensitySpinnerDensityTabValue(val);
    };

    const onMaxDensitySpinnerDensityTabChangeSlider = val => {
        setMaxDensitySpinnerDensityTabValue(val);
    };

    const onApplyDensitySpinnerDensityTabClick = e => {
        reRenderCirclePlotsDensity(minDensitySpinnerDensityTabValue, maxDensitySpinnerDensityTabValue);
    };

    const getStatValues = (data, flow) => {
        let result = {};

        try {
            const average = (array) => array.reduce((a, b) => a + b) / array.length;

            let radiusColumn = data.map(r => r.radius);
            let minRadius = radiusColumn.filter(w => w != null && w != undefined && w != '').map(r => parseInt(Math.floor(parseFloat(r))));
            let minRadiusVal = minRadius.min();
            let maxRadius = radiusColumn.filter(w => w != null && w != undefined && w != '').map(r => parseInt(Math.floor(parseFloat(r))));
            let maxRadiusVal = maxRadius.max();

            let ringColumn = data.map(r => r.ring);
            const distinct = (value, index, self) => {
                return self.indexOf(value) === index;
            };
            let ringsNumber = ringColumn.filter(distinct).map(r => parseInt(r)).length;

            let tilesColumn = data.map(r => r.tileID);
            let tilesNumber = tilesColumn.length;

            result.Flow = parseInt(flow);
            result.MinRadius = minRadiusVal;
            result.MaxRadius = maxRadiusVal;
            result.TotalRingsNumber = ringsNumber;
            result.TotalTilesNumber = tilesNumber;

            if (flow === "1") {
                let beadsColumn = data.map(r => r.beadsPerTile).filter(w => w != "").filter(w => w != null && w != undefined && w != null);
                let beadsColumn_250GL = data.map(r => r.beadsPerTile_ForSignals_250GL).filter(w => w != "").filter(w => w != null && w != undefined && w != null);
                let beadsColumnAll = data.map(r => r.beadsPerTile_All).filter(w => w != "").filter(w => w != null && w != undefined && w != null);
                let beadsColumnAll_250GL = data.map(r => r.beadsPerTile_ForSignals_250GL_All).filter(w => w != "").filter(w => w != null && w != undefined && w != null);
                let beadsNumber = parseFloat(beadsColumn.map(r => parseFloat(r)).reduce((a, b) => a + b, 0));
                let beadsNumberall = beadsColumnAll.length != 0 ? beadsColumnAll.filter(w => w != null && w != undefined && w != null).map(r => parseInt(r)).reduce((a, b) => a + b, 0) : 0;
                let beadsNumberall_250GL = beadsColumnAll_250GL.length > 0 ? beadsColumnAll_250GL.map(r => parseFloat(r)).reduce((a, b) => a + b, 0) : 0;

                let sortedBeads = data.map(r => r.beadsPerTile).filter(w => w != null && w != undefined && w != null).sort((a, b) => (parseFloat(a.beadsPerTile) > parseFloat(b.beadsPerTile)) ? 1 : ((parseFloat(b.beadsPerTile) > parseFloat(a.beadsPerTile)) ? -1 : 0));
                //var sortedBeads = data.Where(w => !string.IsNullOrEmpty(w.BeadsPerTile)).OrderBy(o => Convert.ToDouble(o.BeadsPerTile)).ToList();
                let sortedBeadsCount = sortedBeads.length;
                let min10 = parseInt((sortedBeadsCount * 10) / 100);
                let max10 = parseInt((sortedBeadsCount * 90) / 100);

                let minBeadsNumber10 = sortedBeads[min10].beadsPerTile === undefined ? "0" : sortedBeads[min10].beadsPerTile;
                let maxBeadsNumber10 = sortedBeads[max10].beadsPerTile === undefined ? "0" : sortedBeads[max10].beadsPerTile;
                let minBeadsNumber = minBeadsNumber10;
                let maxBeadsNumber = maxBeadsNumber10;

                let avgBeadsPerTileNumberArr = beadsColumn.map(r => parseFloat(r));
                let avgBeadsPerTileNumber = average(avgBeadsPerTileNumberArr);

                ////For Beads with signals > 250GL
                let sortedBeads_250GL = data.filter(w => w.beadsPerTile_ForSignals_250GL != null && w.beadsPerTile_ForSignals_250GL != '' && w.beadsPerTile_ForSignals_250GL != undefined).sort((a, b) => (parseFloat(a.beadsPerTile_ForSignals_250GL) > parseFloat(b.beadsPerTile_ForSignals_250GL)) ? 1 : ((parseFloat(b.beadsPerTile_ForSignals_250GL) > parseFloat(a.beadsPerTile_ForSignals_250GL)) ? -1 : 0));
                let sortedBeadsCount_250GL = sortedBeads_250GL.length;
                let min10_250GL = parseInt((sortedBeadsCount_250GL * 10) / 100);
                let max10_250GL = parseInt((sortedBeadsCount_250GL * 90) / 100);

                let minBeadsNumber10_250GL = sortedBeads_250GL.length > 0 ? sortedBeads_250GL[min10_250GL].beadsPerTile_ForSignals_250GL === "" ? "0" : sortedBeads_250GL[min10_250GL].beadsPerTile_ForSignals_250GL : "0";
                let temp = sortedBeads_250GL[max10_250GL];
                let maxBeadsNumber10_250GL = sortedBeads_250GL.length > 0 ? sortedBeads_250GL[max10_250GL].beadsPerTile_ForSignals_250GL : "0";
                let minBeadsNumber_250GL = minBeadsNumber10_250GL;
                let maxBeadsNumber_250GL = maxBeadsNumber10_250GL;
                let avgBeadsPerTileNumber_250GL = beadsColumn_250GL.length > 0 ? average(beadsColumn_250GL.map(r => parseFloat(r))) : 0;
                ///

                let signalsColumn = data.map(r => r.mean_Sig).filter(w => w != "");
                let signalsNumber = signalsColumn.map(r => parseFloat(r)).reduce((a, b) => a + b, 0);
                let sortedSignals = data.filter(m => m.mean_Sig != '').sort((a, b) => (parseFloat(a.mean_Sig) > parseFloat(b.mean_Sig)) ? 1 : ((parseFloat(b.mean_Sig) > parseFloat(a.mean_Sig)) ? -1 : 0));
                let sortedSignalsCount = sortedSignals.length;
                let min10_signals = parseInt((sortedSignalsCount * 10) / 100);
                let max10_signals = parseInt((sortedSignalsCount * 90) / 100);
                let minSignalsNumber10 = parseFloat(sortedSignals[min10].mean_Sig);
                let maxSignalsNumber10 = parseFloat(sortedSignals[max10].mean_Sig);
                let minSignalsNumber = minSignalsNumber10;
                let maxSignalsNumber = maxSignalsNumber10;
                let avgSignalsPerTileNumber = average(signalsColumn.map(r => parseFloat(r)));

                let snrColumn = data.map(r => parseFloat(r.mean_Sig != "" ? r.mean_Sig : "0") / parseFloat(r.mean_SigStd != "" ? r.mean_SigStd : "0"));
                let snrNumber = snrColumn.map(r => parseFloat(r)).reduce((a, b) => a + b, 0);
                let sortedSnr = data.filter(f => f.mean_Sig != '' && f.mean_SigStd != "").sort((a, b) => (parseFloat(a.mean_Sig) / parseFloat(a.mean_SigStd) > parseFloat(b.mean_Sig) / parseFloat(b.mean_SigStd)) ? 1 : ((parseFloat(b.mean_Sig) / parseFloat(b.mean_SigStd) > parseFloat(a.mean_Sig) / parseFloat(a.mean_SigStd)) ? -1 : 0));
                let sortedSnrCount = sortedSnr.length;
                let min10_snr = parseInt((sortedSnrCount * 10) / 100);
                let max10_min10_snr = parseInt((sortedSnrCount * 90) / 100);
                let minSnrNumber10 = parseFloat(sortedSnr[min10].mean_Sig) / parseFloat(sortedSnr[min10].mean_SigStd);
                let maxSnrNumber10 = parseFloat(sortedSnr[max10].mean_Sig) / parseFloat(sortedSnr[max10].mean_SigStd);
                let minSnrNumber = minSnrNumber10;
                let maxSnrNumber = maxSnrNumber10;
                let avgSnrPerTileNumber = average(snrColumn.map(r => parseFloat(r)));

                result.TotalBeadsNumber = beadsNumberall;
                result.TotalBeadsNumber_ForSignals_250GL = beadsNumberall_250GL;
                result.MinBeadsNumber = minBeadsNumber;
                result.MaxBeadsNumber = maxBeadsNumber;
                result.AvgBeadsPerTileNumber = avgBeadsPerTileNumber.toFixed(3);
                result.MinBeadsNumber_250GL = minBeadsNumber_250GL;
                result.MaxBeadsNumber_250GL = maxBeadsNumber_250GL;
                result.AvgBeadsPerTileNumber_250GL = avgBeadsPerTileNumber_250GL.toFixed(2);
                result.MinSignalsNumber = minSignalsNumber;
                result.MaxSignalsNumber = maxSignalsNumber;
                result.AvgSignalsPerTileNumber = avgSignalsPerTileNumber.toFixed(3);
                result.MinSnrNumber = minSnrNumber;
                result.MaxSnrNumber = maxSnrNumber;
                result.AvgSnrPerTileNumber = avgSnrPerTileNumber.toFixed(3);
            }
            else {
                let beadsColumn = data.filter(w => w.count_beads_sig_gt_threshold != "").map(r => r.count_beads_sig_gt_threshold);
                let beadsColumnAll = data.filter(w => w.count_beads_sig_gt_threshold_All != "" && w.count_beads_sig_gt_threshold_All != null).map(r => r.count_beads_sig_gt_threshold_All);
                let beadsNumber = parseInt(beadsColumn.map(r => parseFloat(r)).reduce((a, b) => a + b, 0));
                let beadsNumberAll = beadsColumnAll.length != 0 ? beadsColumnAll.filter(w => w != '' && w != null && w != ' ' && w != undefined).map(r => parseInt(r)).reduce((a, b) => a + b, 0) : 0;

                let sortedBeads = data.sort((a, b) => (parseFloat(a.count_beads_sig_gt_threshold) > parseFloat(b.count_beads_sig_gt_threshold)) ? 1 : ((parseFloat(b.count_beads_sig_gt_threshold) > parseFloat(a.count_beads_sig_gt_threshold)) ? -1 : 0));
                let sortedBeadsCount = sortedBeads.length;
                let min10 = parseInt((sortedBeadsCount * 10) / 100);
                let max10 = parseInt((sortedBeadsCount * 90) / 100);
                let minBeadsNumber10 = sortedBeads[min10].count_beads_sig_gt_threshold;
                let maxBeadsNumber10 = sortedBeads[max10].count_beads_sig_gt_threshold;
                let minBeadsNumber = minBeadsNumber10;
                let maxBeadsNumber = maxBeadsNumber10;
                let avgBeadsPerTileNumber = average(beadsColumn.map(r => parseFloat(r)));

                let signalsColumn = data.map(r => r.mean_Sig).filter(w => w != "");
                let signalsNumber = signalsColumn.filter(w => w != null).map(r => parseFloat(r)).reduce((a, b) => a + b, 0);
                let sortedSignals = data.filter(f => f.mean_Sig != '').sort((a, b) => (parseFloat(a.mean_Sig) > parseFloat(b.mean_Sig)) ? 1 : ((parseFloat(b.mean_Sig) > parseFloat(a.mean_Sig)) ? -1 : 0));
                let sortedSignalsCount = sortedSignals.length;
                let min10_signals = parseInt((sortedSignalsCount * 10) / 100);
                let max10_signals = parseInt((sortedSignalsCount * 90) / 100);
                let minSignalsNumber10 = parseFloat(sortedSignals[min10_signals].mean_Sig);
                let maxSignalsNumber10 = parseFloat(sortedSignals[max10_signals].mean_Sig);
                let minSignalsNumber = minSignalsNumber10;
                let maxSignalsNumber = maxSignalsNumber10;
                let avgSignalsPerTileNumber = average(signalsColumn.map(r => parseFloat(r)));

                let snrColumn = data.map(r => parseFloat(r.mean_Sig != "" ? r.mean_Sig : "0") / parseFloat(r.mean_SigStd != "" ? r.mean_SigStd : "0"));
                let snrNumber = snrColumn.map(r => parseFloat(r)).reduce((a, b) => a + b);
                let sortedSnr = data.filter(f => f.mean_Sig != '').sort((a, b) => (parseFloat(a.mean_Sig) > parseFloat(b.mean_Sig)) ? 1 : ((parseFloat(b.mean_Sig) > parseFloat(a.mean_Sig)) ? -1 : 0));
                let sortedSnrCount = sortedSnr.length;
                let min10_snr = parseInt((sortedSnrCount * 10) / 100);
                let max10_snr = parseInt((sortedSnrCount * 90) / 100);
                let minSnrNumber10 = parseFloat(sortedSnr[min10_snr].mean_Sig) / parseFloat(sortedSnr[min10].mean_SigStd);
                let maxSnrNumber10 = parseFloat(sortedSnr[max10_snr].mean_Sig) / parseFloat(sortedSnr[max10].mean_SigStd);
                let minSnrNumber = minSnrNumber10;
                let maxSnrNumber = maxSnrNumber10;
                let avgSnrPerTileNumber = average(snrColumn.map(r => parseFloat(r)));

                result.TotalBeadsNumber = beadsNumberAll;
                result.MinBeadsNumber = minBeadsNumber;
                result.MaxBeadsNumber = maxBeadsNumber;
                result.AvgBeadsPerTileNumber = avgBeadsPerTileNumber.toFixed(3);
                result.MinSignalsNumber = minSignalsNumber;
                result.MaxSignalsNumber = maxSignalsNumber;
                result.AvgSignalsPerTileNumber = avgSignalsPerTileNumber.toFixed(3);
                result.MinSnrNumber = minSnrNumber;
                result.MaxSnrNumber = maxSnrNumber;
                result.AvgSnrPerTileNumber = avgSnrPerTileNumber.toFixed(3);
            }
        }
        catch (error) {
            console.log(error);
        }

        return result;
    }

    const handleDensitySliderChange = value => {
        if (value !== undefined && value.length === 2) {
            onMinDensitySpinnerDensityTabChangeSlider(value[0]);
            onMaxDensitySpinnerDensityTabChangeSlider(value[1]);
            //onApplyDensitySpinnerClick();
        }
    }

    useEffect(() => {
        try {
            if (minDensitySpinnerDensityTabValue !== undefined && maxDensitySpinnerDensityTabValue !== undefined) {
                onApplyDensitySpinnerDensityTabClick();
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [minDensitySpinnerDensityTabValue, maxDensitySpinnerDensityTabValue]);

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    {state.clumpings_table_render_data.length > 0 &&
                        <Card sx={{ minWidth: 275, zIndex: 1, }}>
                            <CardHeader
                                action={
                                    <IconButton
                                        onClick={() => handleExpandClick('d1')}
                                        aria-expanded={expandedIds.includes('d1')}
                                        aria-label="Expand/Collapse"
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                }
                                //title="Flow"
                                subheader={state.runId}
                            />
                            <Collapse in={expandedIds.includes('d1')} timeout="auto">
                                <CardContent>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>
                                            {state.clumpings_table_render_data.length > 0 &&
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            {state.clumpings_table_render_data[0].map((m, i) =>
                                                                <th key={i + 1}>{m}</th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            {state.clumpings_table_render_data[1].map((m, i) =>
                                                                <td key={i + 1}>{m}</td>
                                                            )}
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            }
                                        </ListGroup.Item>
                                    </ListGroup>
                                </CardContent>
                            </Collapse>
                        </Card>
                    }
                </Col>
            </Row>
            <Row style={{ marginTop: 15 }}>
                <Col lg={12}>
                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                        <CardHeader
                            action={
                                <IconButton
                                    onClick={() => handleExpandClick('d2')}
                                    aria-expanded={expandedIds.includes('d2')}
                                    aria-label="Expand/Collapse"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                            title="Density"
                            subheader={state.runId}
                        />
                        <Collapse in={expandedIds.includes('d2')} timeout="auto">
                            <CardContent>
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        {densityPlotsData.length > 0 ?
                                            <Row style={{ marginTop: 35 }} className="text-center">
                                                <Col lg={{ span: 6, offset: 3 }}>
                                                    <Box>
                                                        <RangeSliderCommon
                                                            getAriaLabel={() => 'Range'}
                                                            defaultValue={[minDensitySpinnerDensityTabValue, maxDensitySpinnerDensityTabValue]}
                                                            onChange={handleDensitySliderChange}
                                                            valueLabelDisplay={"on"}
                                                            disabled={false}
                                                        />
                                                    </Box>
                                                </Col>
                                            </Row>
                                           : null}
                                        <Row>
                                            {densityPlotsData.length > 0 ? densityPlotsData.map((m, i) =>
                                                <Col key={`${i * 4 / 2}`} lg={6} style={{ marginTop: 15 }}>
                                                    <Card sx={{ minWidth: 275, zIndex: 1, }}>
                                                        <CardHeader
                                                            action={
                                                                <IconButton
                                                                    onClick={() => handleExpandClick('d1')}
                                                                    aria-expanded={expandedIds.includes('d1')}
                                                                    aria-label="Expand/Collapse"
                                                                >
                                                                    <ExpandMoreIcon />
                                                                </IconButton>
                                                            }
                                                            title={`Flow ${m.flow}`}
                                                            subheader={state.runId}
                                                        />
                                                        <Collapse in={expandedIds.includes('d1')} timeout="auto">
                                                            <CardContent>
                                                                <ListGroup variant="flush">
                                                                    <ListGroup.Item>
                                                                        <Row style={{justifyContent: 'center'}}>
                                                                            <Col lg={{ offset: 3, span: 8 }}>                                                                               
                                                                                {<RenderPlot id={`circlePlotDensity${i}`} data={m.data.data_density && m.data.data_density} layout={m.data.layout_density} config={m.data.config} isLoading={state.isHistogramPloatsLoading}
                                                                                    type={'circular'} width={'60%'} height={450} />}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col lg={{ span: 12 }}>
                                                                                {densityStatsTableData.length === densityPlotsData.length &&
                                                                                    <Table responsive>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Min radius</th>
                                                                                                <th>Max radius</th>
                                                                                                <th>Total number of rings</th>
                                                                                                <th>Total number of tiles</th>
                                                                                                <th>Total number of beads</th>
                                                                                                <th>Lowest 10% bead density per mm²</th>
                                                                                                <th>Highest 10% bead density per mm²</th>
                                                                                                <th>Average bead density per mm²</th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td>{densityStatsTableData[i].MinRadius}</td>
                                                                                                <td>{densityStatsTableData[i].MaxRadius}</td>
                                                                                                <td>{densityStatsTableData[i].TotalRingsNumber}</td>
                                                                                                <td>{densityStatsTableData[i].TotalTilesNumber}</td>
                                                                                                <td>{densityStatsTableData[i].TotalBeadsNumber}</td>
                                                                                                <td>{densityStatsTableData[i].MinBeadsNumber}</td>
                                                                                                <td>{densityStatsTableData[i].MaxBeadsNumber}</td>
                                                                                                <td>{densityStatsTableData[i].AvgBeadsPerTileNumber}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td colSpan="8">{'*Beads with Signal > 250GL'}</td>
                                                                                            </tr>
                                                                                            <tr>
                                                                                                <td>{densityStatsTableData[i].MinRadius}</td>
                                                                                                <td>{densityStatsTableData[i].MaxRadius}</td>
                                                                                                <td>{densityStatsTableData[i].TotalRingsNumber}</td>
                                                                                                <td>{densityStatsTableData[i].TotalTilesNumber}</td>
                                                                                                <td>{densityStatsTableData[i].TotalBeadsNumber_ForSignals_250GL}</td>
                                                                                                <td>{densityStatsTableData[i].MinBeadsNumber_250GL}</td>
                                                                                                <td>{densityStatsTableData[i].MaxBeadsNumber_250GL}</td>
                                                                                                <td>{densityStatsTableData[i].AvgBeadsPerTileNumber_250GL}</td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </Table>
                                                                                }
                                                                            </Col>
                                                                        </Row>
                                                                    </ListGroup.Item>
                                                                </ListGroup>
                                                            </CardContent>
                                                        </Collapse>
                                                    </Card>
                                                </Col>
                                            ) : state.isHistogramPloatsLoading ? <Col lg={12}><LoadingSpinner /></Col> : <ShowAlert type={'warning'} text='No data found' />}
                                        </Row>
                                    </ListGroup.Item>
                                </ListGroup>
                            </CardContent>
                        </Collapse>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Density;