import React, { useEffect, memo } from 'react';
import Plotly from './customPlotly';
import createPlotlyComponent from "react-plotly.js/factory";
import Skeleton from '@mui/material/Skeleton';
import { addPlotlyPlotToList, addPlotlyURIList } from "../actions/index";
import { useDispatch, useSelector } from "react-redux";

const RenderPlot = memo((props) => {
    const [plotData, setPlotData] = React.useState(undefined);
    const [plotLayout, setPlotLayout] = React.useState({});
    const [plotConfig, setPlotConfig] = React.useState({});

    const { data, layout, config, id, isLoading, type, width, height } = props;

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const Plot = createPlotlyComponent(Plotly);

    useEffect(() => {
        setPlotData([]);
        setPlotLayout([]);
        setPlotConfig([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data != undefined && data.length > 0) {
            setPlotData(data);
            setPlotLayout(layout);
            setPlotConfig(config);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (id !== undefined) {
            dispatch(addPlotlyPlotToList(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (id !== undefined && plotData != undefined && plotData.length > 0) {
            dispatch(addPlotlyURIList({runId: state.runId, id, plotData, plotLayout}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.runId, id, plotData, plotLayout]);

    return (
        !isLoading && plotData !== undefined && plotData.length > 0 ?
            <Plot
                divId={id}
                graphDiv={id}
                data={plotData && plotData.map(m => m)}
                layout={plotLayout && plotLayout}
                config={plotConfig}
                useResizeHandler={true}
                style={{ width: '100%', height: '100%' }}
            />
            :
            <>
                <Skeleton variant={type} width={width} style={{ marginTop: 15, height: height, }} />
                <Skeleton variant={'rounded'} width={width} style={{ marginTop: 15 }} />
            </>
    );
});

export default RenderPlot;
