import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setRunConfigTableRenderData, setRunSummaryDensityPlotIsLoading, setRunSummaryDensityPlotByCameraValue, getRunConfigTableDataAsync, setRunSummaryDensityPlotRenderData, setRunDetailsTablePdf } from "../actions/index";
import { Row, Col, } from "react-bootstrap";
import Table from 'react-bootstrap/Table';
import Image from '../image/image';
import * as Utils from '../../utils/utils';
import SkeletonLoader from '../skeletonloader/skeletonloader';
import RenderPlot from '../plots/renderplot';
import * as RenderRunConfigPlots from '../../plots/runconfiguration/runconfiguration';
import useDeviceQuery from '../../utils/hooks/useDeviceMedia';
import * as RenderCirclePlots from '../../plots/circleplots/circleplots';
import RenderSignalCharts from '../../plots/signalplots/signalplots';
import SummaryHeader from '../summaryheader/summaryheader';
import * as RenderReadLengthPlots from '../../plots/readlengthplots/readlengthplots';
import Container from '@mui/material/Container';

const RunConfiguration = (props) => {

    const dispatch = useDispatch();

    const state = useSelector(state => state);

    const [data, setData] = useState([]);
    const [dataPlots, setDataPlots] = useState([]);
    const [isDataTablesLoading, setDataTablesLoading] = useState(true);
    const [rsqPlotsData, setRSQPlotsData] = useState([]);
    const [runSummaryDensityPlotData, setRunSummaryDensityPlotData] = useState([]);
    const [renderMenuItemsData, setRenderMenuItemsData] = useState([]);
    const { isMedium1Screen, isMedium2Screen, isSmallScreen } = useDeviceQuery();
    const [minDensitySpinnerValue] = React.useState(state.spinnerDensity.min);
    const [maxDensitySpinnerValue] = React.useState(state.spinnerDensity.max);
    const [minSignalSpinnerValue] = React.useState(state.spinnerSignal.min);
    const [maxSignalSpinnerValue] = React.useState(state.spinnerSignal.max);
    const [minSNRSpinnerValue] = React.useState(state.spinnerSNR.min);
    const [maxSNRSpinnerValue] = React.useState(state.spinnerSNR.max);
    const [runSummarySignalPlotsData, setRunSummarySignalPlotsData] = React.useState(undefined);
    const [firstFlowSignal, setFirstFlowSignal] = React.useState(undefined);
    const [totalBeads, setTotalBeads] = React.useState(undefined);
    const [passFilter, setPassFilter] = React.useState(undefined);
    const [totalReadLengthPlotData, setTotalReadLengthPlotData] = useState(undefined);
    const [readLength, setReadLength] = useState(undefined);
    
    useEffect(() => {
        setRunSummaryDensityPlotData(undefined);
        setData([]);
        setDataPlots([]);
        setRSQPlotsData([]);
        setRenderMenuItemsData([]);
        setTotalBeads(undefined);
        setPassFilter(undefined);
        setFirstFlowSignal(undefined);
        setTotalReadLengthPlotData(undefined);
        setReadLength(undefined);
        Utils.purgePlot('totalReadLengthPlot');
        Utils.purgePlot('runSummaryDensityPlot1');
        Utils.purgePlot('rsq1');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.runId]);

    useEffect(() => {
        try {
            let exists = state.signal_plots_data.filter(f => f.runid === state.runId);
            if (exists.length > 0) {
                let data = exists[0].data;
                if (data.data) {
                    let byCameraTemp = data.data[state.by_camera_value_global];
                    if (byCameraTemp && byCameraTemp.length > 0) {
                        let jsData = Utils.transformData(byCameraTemp);
                        let signalRec = jsData.filter(f => f.flow === 1);
                        if (signalRec && signalRec.length > 0) setFirstFlowSignal(!isNaN(signalRec[0]['mean_Sig']) ? parseInt(signalRec[0]['mean_Sig']) : signalRec[0]['mean_Sig']);
                        // data.data = jsData;                        
                        let signal = RenderSignalCharts({ data: jsData }, data.flows !== undefined && data.flows !== null && data.flows.length > 0 ? data.flows : null, data.noGap !== undefined && data.noGap !== null && data.noGap.length > 0 ? data.noGap : null, data.defaultFlowOrder);
                        if (signal !== undefined) setRunSummarySignalPlotsData(signal);
                        else setRunSummarySignalPlotsData(undefined);
                    }
                    else setRunSummarySignalPlotsData(undefined);
                }
                else {
                    setRunSummarySignalPlotsData(undefined);
                }
            }
        }
        catch (e) {
            setRunSummarySignalPlotsData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.signal_plots_data, state.by_camera_value_global]);

    useEffect(() => {
        if (data === undefined && data.length === 0) {
            setDataTablesLoading(true);
        }
        else setDataTablesLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        try {
            if (state.current_run_data && state.current_run_data.length > 0) {
                let updateRunId = state.runId.includes('.ver') ? state.runId.split('.')[0] : state.runId;
                let targetRunId = Utils.updateSearchRun(updateRunId, state.by_camera_value_global);
                let numBeads;
                try {
                    const targetObject = state.current_run_data.find(obj => obj.runid === targetRunId);
                    if (targetObject) {
                        numBeads = targetObject.numbeads;
                        if (numBeads) setTotalBeads(numBeads);
                    } else {
                        const subRowObject = state.current_run_data.find(obj => obj.subRows && obj.subRows.some(sub => sub.runid === targetRunId));
                        if (subRowObject) {
                            numBeads = subRowObject.subRows.find(sub => sub.runid === targetRunId).numbeads;
                            if (numBeads) setTotalBeads(numBeads);
                        }
                    }
                }
                catch {
                    setTotalBeads(undefined);
                }
                try {                  
                    let exists = state.beads_filtering_plots.filter(f => f.runid === state.runId);
                    if (exists !== undefined && exists.length > 0) {
                        if (exists[0].data !== undefined && exists[0].data.type === 'h5' && exists[0].data.data !== undefined) {
                            let data = exists[0].data.data[state.by_camera_value_global];
                            let total_reads_passed_perc = data.barcodes.indexOf('allReads') !== -1 ? (data.passed_rsq[data.barcodes.indexOf('allReads')]).toFixed(2) : (data.passed_rsq[data.barcodes.indexOf('allReads')]);
                            setPassFilter(parseInt(total_reads_passed_perc));
                        }
                        else setPassFilter(undefined);
                    }
                }
                catch {
                    setPassFilter(undefined);
                }
            }
        }
        catch {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.current_run_data, state.runId, state.by_camera_value_global, state.beads_filtering_plots]);

    useEffect(() => {
        if (dataPlots !== undefined && dataPlots.plot1 === undefined && dataPlots.plot2 === undefined) {
            dispatch(setRunSummaryDensityPlotIsLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPlots]);

    useEffect(() => {
        try {
            let exists = state.run_summary_density_plots_data.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.flow === state.flow);
            if (exists.length > 0) {
                if (exists[0].data !== undefined && exists[0].data !== null) {
                    if (state.by_camera_value_global === 'camera_1') {
                        let plotsData = exists[0].data.filter(f => f.Camera === 1);
                        reRenderCirclePlotsRunSummaryDensity(plotsData, minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue);
                    }
                    else if (state.by_camera_value_global === 'camera_2') {
                        let plotsData = exists[0].data.filter(f => f.Camera === 2);
                        reRenderCirclePlotsRunSummaryDensity(plotsData, minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue);
                    }
                    else reRenderCirclePlotsRunSummaryDensity(exists[0].data, minDensitySpinnerValue, maxDensitySpinnerValue, minSignalSpinnerValue, maxSignalSpinnerValue, minSNRSpinnerValue, maxSNRSpinnerValue);
                }
                else dispatch(setRunSummaryDensityPlotRenderData(state.runId, state.run_summary_density_plots_by_camera_value, state.flow, undefined));
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.run_summary_density_plots_data, state.by_camera_value_global]);

    const reRenderCirclePlotsRunSummaryDensity = (data, minDensityVal, maxDensityVal, minSignalVal, maxSignalVal, minSnrVal, maxSnrVal, notToSaveToState) => {
        try {
            if (data !== undefined && data.length > 0) {
                let res = data.map(m => {
                    return {
                        'beadsPerTile': m.BeadsPerTile,
                        'beadsPerTile_ForSignals_250GL': m.BeadsPerTile_ForSignals_250GL,
                        'flow': m.Flow,
                        'tileID': m.TileID,
                        'tileOffset': m.TileOffset,
                        'ring': m.Ring,
                        'radius': m.Radius,
                        'theta': m.Theta,
                        'mean_Sig': m.Mean_Sig,
                        'stdDev_Sig': m.StdDev_Sig,
                        'mean_SigStd': m.Mean_SigStd,
                        'count_beads_sig_gt_threshold': m.Count_beads_sig_gt_threshold,
                        'mean_beads_sig_gt_threshold': m.Mean_beads_sig_gt_threshold
                    }
                });
                let tl = state.tileArea.filter(f => f.runid === Utils.getRunIdWithoutCamera(state.runId) && f.instrId === state.instrumentId);
                let circle = RenderCirclePlots.RenderCircleCharts(res, 'radius', 'theta', 'beadsPerTile', state.flow, minDensityVal, maxDensityVal, minSignalVal, maxSignalVal, minSnrVal, maxSnrVal, tl !== undefined && tl.length > 0 ? tl[0].data.TileArea : undefined, state.isRunBaseLine, true, state.runId, tl !== undefined && tl.length > 0 ? tl[0].data.Crop : undefined,
                    tl !== undefined && tl.length > 0 ? tl[0].data.PixSize : undefined, tl !== undefined && tl.length > 0 ? tl[0].data.TileSizeX : undefined, tl !== undefined && tl.length > 0 ? tl[0].data.TileSizeY : undefined, minSnrVal, maxSnrVal, undefined, undefined, state.by_camera_value_global);
                dispatch(setRunSummaryDensityPlotRenderData(state.runId, state.by_camera_value_global, state.flow, { data_density: circle.data_density, layout_density: circle.layout_density, config: circle.config }));                
            }
            else {
                //dispatch(setRunSummaryDensityPlotRenderData(state.runId, state.run_summary_density_plots_by_camera_value, state.flow, undefined));
            }
        }
        catch { }
    };

    useEffect(() => {
        try {
            dispatch(setRunSummaryDensityPlotIsLoading(true));
            let exists = state.run_summary_density_plots_render_data.filter(f => f.runid === state.runId && f.camera === state.by_camera_value_global && f.flow === state.flow);
            if (exists.length > 0 && exists[0].data !== undefined) {
                setRunSummaryDensityPlotData(exists[0].data);
                dispatch(setRunSummaryDensityPlotIsLoading(false));
            }
            else {
                let plotsDataExists = state.run_config_plots.filter(f => f.runid === state.runId).length > 0;
                if (plotsDataExists !== undefined && plotsDataExists === true) {
                    let plotsData = state.run_config_plots.filter(f => f.runid === state.runId)[0].data;
                    if (plotsData !== undefined && plotsData.plot1 !== undefined) {
                        setDataPlots({ plot1: plotsData.plot1, plot2: plotsData.plot2 });
                    }
                    setRunSummaryDensityPlotData(undefined);
                    dispatch(setRunSummaryDensityPlotIsLoading(false));
                }
                else {
                    setDataPlots({ plot1: undefined });
                    setRunSummaryDensityPlotData(undefined);
                    dispatch(setRunSummaryDensityPlotIsLoading(false));
                }
            }
        }
        catch {
            setDataPlots({ plot1: undefined });
            setRunSummaryDensityPlotData(undefined);
            dispatch(setRunSummaryDensityPlotIsLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.run_summary_density_plots_render_data]);

    useEffect(() => {
        try {
            if (state.run_config_table_data.length) {
                let exists = state.run_config_table_data.filter(f => f.runid === state.runId);
                if (exists !== undefined && exists.length) {
                    let data = exists[0].data;
                    if (data === undefined) dispatch(setRunConfigTableRenderData({}, state.runId));
                    let merged = data.map(m => {
                        try {
                            if (m.camera_1 !== undefined && (m.camera_2 === undefined || m.camera_2.length === 0)) {
                                m.camera_2 = m.camera_1;
                                return m;
                            }
                            else if (m.camera_2 !== undefined && (m.camera_1 === undefined || m.camera_1.length === 0)) {
                                m.camera_1 = m.camera_2;
                                return m;
                            }
                            else return m;
                        }
                        catch {
                            return m;
                        }
                    });
                    let cam = `camera_1`;
                    let flatted = merged.flat();
                    const renderData = {};
                    let genomeFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'genome') : flatted.filter(f => f[0] === 'genome');
                    if (genomeFound && genomeFound.length > 0) renderData.genome = genomeFound[0][1];
                    let reportDateFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'report date') : flatted.filter(f => f[0] === 'report date');
                    if (reportDateFound && reportDateFound.length > 0) renderData.reportDate = reportDateFound[0][1];
                    //Pipeline version file
                    let pipelineVersionFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'pipeline version') : flatted.filter(f => f[0] === 'pipeline version');
                    if (pipelineVersionFound && pipelineVersionFound.length > 0) renderData.pipelineVersion = pipelineVersionFound[0][1];
                    let chemistryVersionFound = flatted.filter(f => f[0] === 'chemistry version');
                    if (chemistryVersionFound && chemistryVersionFound.length > 0) renderData.chemistryVersion = chemistryVersionFound[0][1];
                    let flowLengthFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'flows length') : flatted.filter(f => f[0] === 'flows length');
                    if (flowLengthFound && flowLengthFound.length > 0) renderData.baseCallingReportFlows = flowLengthFound[0][1];
                    let preambleFlowsFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'Preamble') : flatted.filter(f => f[0] === 'Preamble');
                    if (preambleFlowsFound && preambleFlowsFound.length > 0) renderData.preambleFlows = preambleFlowsFound[0][1];
                    let readsInTrainingSetFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'Signal input length') : flatted.filter(f => f[0] === 'Signal input length');
                    if (readsInTrainingSetFound && readsInTrainingSetFound.length > 0) renderData.readsInTrainingSet = readsInTrainingSetFound[0][1];
                    let flowOrderFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'flow_order') : flatted.filter(f => f[0] === 'flow_order');
                    if (flowOrderFound && flowOrderFound.length > 0) renderData.flowOrder = flowOrderFound[0][1];
                    let barcodeRegionFlowsFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'UncertainFlows') : flatted.filter(f => f[0] === 'UncertainFlows');
                    if (barcodeRegionFlowsFound && barcodeRegionFlowsFound.length > 0) renderData.barcodeRegionFlows = barcodeRegionFlowsFound[0][1];
                    let repoHashesFound = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f[0] === 'repo_hashes') : flatted.filter(f => f[0] === 'repo_hashes');
                    if (repoHashesFound && repoHashesFound.length > 0) renderData.repoHashes = repoHashesFound[0][1];

                    let ar = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.AnalysisRecipe !== undefined) : flatted.filter(f => f.AnalysisRecipe !== undefined);
                    if (ar && ar.length > 0) renderData.analysisRecipe = ar[0].AnalysisRecipe;
                    let r = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.Recipe !== undefined) : flatted.filter(f => f.Recipe !== undefined);
                    if (r && r.length > 0) renderData.recipe = r[0].Recipe;
                    let ast = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.Analysis_Start_Time !== undefined) : flatted.filter(f => f.Analysis_Start_Time !== undefined);
                    if (ast && ast.length > 0) renderData.analysisStartDate = ast[0].Analysis_Start_Time;
                    let bm = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.Golden_Model !== undefined) : flatted.filter(f => f.Golden_Model !== undefined);
                    if (bm && bm.length > 0) renderData.bootstrapModel = bm[0].Golden_Model;
                    let pv = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.PegasusVersion !== undefined) : flatted.filter(f => f.PegasusVersion !== undefined);
                    if (pv && pv.length > 0) renderData.pegasusVer = pv[0].PegasusVersion;
                    let av = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.AnalysisVersion !== undefined) : flatted.filter(f => f.AnalysisVersion !== undefined);
                    if (av && av.length > 0) renderData.analysisVer = av[0].AnalysisVersion;
                    let fv = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.PhotometryVersion !== undefined) : flatted.filter(f => f.PhotometryVersion !== undefined);
                    if (fv && fv.length > 0) renderData.photometryVer = fv[0].PhotometryVersion;
                    let rd = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.ReagentDrawer !== undefined) : flatted.filter(f => f.ReagentDrawer !== undefined);
                    if (rd && rd.length > 0) renderData.reagentDrawer = rd[0].ReagentDrawer;
                    let rv = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.RecipeVersion !== undefined) : flatted.filter(f => f.RecipeVersion !== undefined);
                    if (rv && rv.length > 0) renderData.recipeVer = rv[0].RecipeVersion;
                    let sr = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.SampleRack !== undefined) : flatted.filter(f => f.SampleRack !== undefined);
                    if (sr && sr.length > 0) renderData.sampleRack = sr[0].SampleRack;
                    let st = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.SampleTube !== undefined) : flatted.filter(f => f.SampleTube !== undefined);
                    if (st && st.length > 0) renderData.sampleTube = st[0].SampleTube;
                    let sc = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.SequencingCartridge !== undefined) : flatted.filter(f => f.SequencingCartridge !== undefined);
                    if (sc && sc.length > 0) renderData.sequencingCart = sc[0].SequencingCartridge;
                    let wc = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.WashContainer !== undefined) : flatted.filter(f => f.WashContainer !== undefined);
                    if (wc && wc.length > 0) renderData.washContainer = wc[0].WashContainer;
                    let w = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.Wafer !== undefined) : flatted.filter(f => f.Wafer !== undefined);
                    if (w && w.length > 0) renderData.wafer = w[0].Wafer;
                    let sdbv = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.SampleDbVersion !== undefined) : flatted.filter(f => f.SampleDbVersion !== undefined);
                    if (sdbv && sdbv.length > 0) renderData.sampleDbV = sdbv[0].SampleDbVersion;
                    let rpf = flatted.filter(f => f.ReadsPassedFilter !== undefined);
                    if (rpf && rpf.length > 0) renderData.readsPassedFilter = rpf[0].ReadsPassedFilter !== undefined && rpf[0].ReadsPassedFilter !== null && !isNaN(rpf[0].ReadsPassedFilter) ? Utils.numberWithCommas(parseInt(rpf[0].ReadsPassedFilter)) : rpf[0].ReadsPassedFilter;    
                    let asv = state.runId !== undefined && state.runId.includes('_') ? flatted.map(m => m[cam]).filter(f => f !== undefined).flat().filter(f => f.AmpScriptVersion !== undefined) : flatted.filter(f => f.AmpScriptVersion !== undefined);
                    if (asv && asv.length > 0) renderData.ampScriptVer = asv[0].AmpScriptVersion;               
                    dispatch(setRunConfigTableRenderData(renderData, state.runId, state.by_camera_value_global));
                }
                else dispatch(getRunConfigTableDataAsync(state.runId, state.flow, state.jwt));
            }
        }
        catch (e) {
            //console.log(e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.run_config_table_data, state.runId, state.by_camera_value_global]);

    useEffect(() => {
        try {
            if (state.run_config_table_render_data.filter(f => f.runid === state.runId && f.camera === state.by_camera_value_global).length > 0) {
                setData(state.run_config_table_render_data.filter(f => f.runid === state.runId && f.camera === state.by_camera_value_global)[0].data);
            }
            else setData([]);
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.run_config_table_render_data]);

    useEffect(() => {
        try {
            setRSQPlotsData([]);
            if (state.run_config_plots.filter(f => f.runid === state.runId).length > 0) {
                let plotsData = state.run_config_plots.filter(f => f.runid === state.runId)[0].data;
                if (plotsData.plot2 !== undefined && typeof plotsData.plot2 === 'object') {
                    let circle = RenderRunConfigPlots.RenderRSQCharts(plotsData.plot2[state.by_camera_value_global], state.runId, 0, 1, state.by_camera_value_global);
                    setRSQPlotsData(circle);
                    setDataPlots(undefined);
                }
                else {
                    setRSQPlotsData(undefined);
                    setDataPlots({ plot2: plotsData.plot2 });
                }
            }
            else {
                setDataPlots({ plot2: undefined });
                setRSQPlotsData([]);
            }
        }
        catch (error) {
            setDataPlots({ plot2: undefined });
            setRSQPlotsData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.run_config_plots, state.runId]);


    useEffect(() => {
        try {
            Utils.purgePlot('signalPlot2');
            Utils.purgePlot('runSummaryDensityPlot1');
            Utils.purgePlot('rsq1');
            Utils.purgePlot('totalReadLengthPlot');
            dispatch(setRunSummaryDensityPlotByCameraValue(state.by_camera_value_global));
            let exists = state.run_config_plots.filter(f => f.runid === state.runId);
            if (exists !== undefined && exists.length > 0 && exists[0].data !== undefined) {
                let plotsData = state.run_config_plots.filter(f => f.runid === state.runId)[0].data;
                if (plotsData !== undefined && plotsData.plot2 !== undefined && typeof plotsData.plot2 === 'object') {
                    let circle = RenderRunConfigPlots.RenderRSQCharts(plotsData.plot2[state.by_camera_value_global], state.runId, 0, 1, state.by_camera_value_global);
                    setRSQPlotsData(circle);
                }
            }
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.by_camera_value_global]);

    useEffect(() => {
        if(data && Object.keys(data).length > 0){
            const tableData = {
                runDetailsTable: {
                    "Run Id": Utils.getRunIdWithoutCamera(state.runId),
                    "Details": state.current_run_data[0].details !== undefined ? state.current_run_data[0].details : '',
                    "Genome": data.genome !== undefined ? data.genome : '',
                    "Sequencing Start Date": (state.current_run_data.length > 0 && state.current_run_data[0].startdatetime !== undefined) && Utils.isStructuredDate(state.current_run_data[0].startdatetime) ? state.current_run_data[0].startdatetime : Utils.restructureDate(state.current_run_data[0].startdatetime),
                    "Analysis Start Date": data.analysisStartDate !== undefined ? Utils.modifyFormatDate(data.analysisStartDate) : '',
                    "Monitoring": (data.reportDate && data.reportDate !== undefined && data.reportDate !== null && data.reportDate !== "null") ? Utils.modifyFormatDate(data.reportDate) : '',
                    "Run time": Utils.time_convert((state.current_run_data.length > 0 && state.current_run_data[0].runtime !== undefined) && parseInt(state.current_run_data[0].runtime)),
                    "Flows": (state.current_run_data.length > 0 && state.current_run_data[0].numflows !== undefined) && state.current_run_data[0].numflows,
                    "BaseCalling Report Flows": data.baseCallingReportFlows !== undefined ? data.baseCallingReportFlows : '',
                    "Preamble flows": data.preambleFlows !== undefined ? data.preambleFlows : '',
                    "Barcode Region Flows": data.barcodeRegionFlows !== undefined ? data.barcodeRegionFlows : '',
                    "Reads in training set": data.readsInTrainingSet !== undefined ? data.readsInTrainingSet : '',
                    "# Reads Pass Filter (M)": data.readsPassedFilter !== undefined && Utils.numToIntReads(data.readsPassedFilter),
                    "# Output Reads (M)": (state.current_run_data.length > 0 && state.current_run_data[0].pf_output_reads !== undefined) && Utils.numberWithCommas(parseInt(state.current_run_data[0].pf_output_reads)),
                    "Amp Script": data.ampScriptVer !== undefined ? data.ampScriptVer : '',
                    "Flow order": data.flowOrder !== undefined ? data.flowOrder : '',
                    "Reagent Drawer": data.reagentDrawer !== undefined ? data.reagentDrawer : '',
                    "Sequencing Kit": data.sampleRack && data.sampleTube && data.sequencingCart && data.washContainer && data.wafer ? `Sample Rack: ${data.sampleRack}; Sample Tube: ${data.sampleTube}; Sequencing Cartridge: ${data.sequencingCart}; Wash Container: ${data.washContainer}; Wafer: ${data.wafer};` : '',
                    "Photometry version": data.photometryVer !== undefined ? data.photometryVer : '',
                    "SampleDb version": data.sampleDbV !== undefined ? data.sampleDbV : '',
                    "Sequencing Recipe Version": data.recipeVer !== undefined ? data.recipeVer : '',
                    "Pegasus version": data.pegasusVer !== undefined ? data.pegasusVer : '',
                    "Analysis version": data.analysisVer !== undefined ? data.analysisVer : '',
                    "Analysis Recipe": data.recipe !== undefined ? data.recipe : (data.analysisRecipe !== undefined ? data.analysisRecipe : ''),
                    "Model": data.bootstrapModel !== undefined ? data.bootstrapModel : '',
                },
                firstFlowSignal: firstFlowSignal !== undefined ? firstFlowSignal : '',
                totalbeads: totalBeads !== undefined ? totalBeads : '',
                passFilter: passFilter !== undefined ? passFilter : '',
                readLength : readLength !== undefined ? readLength : ''
            };


            if(tableData !== undefined && Object.keys(tableData).length > 0) dispatch(setRunDetailsTablePdf( state.runId, tableData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.runId, data, readLength, passFilter, firstFlowSignal, totalBeads, state.current_run_data]);


    useEffect(() => {
        try {
            Utils.purgePlot('totalReadLengthPlot');
            setTotalReadLengthPlotData(undefined);

            let exists = state.read_length_plots.filter(f => f.runid === state.runId);
            if (exists.length > 0 && exists[0].data !== undefined) {
                if (exists[0].data.plot3 !== undefined) {
                    let data = exists[0].data.plot3[state.by_camera_value_global];
                    let readLength = parseInt(Utils.getArrayAverage(data['All reads'], data['Length']));
                    if (readLength) setReadLength(readLength);
                    let rl1 = RenderReadLengthPlots.RenderReadLengthPlot(data, state.runId, state.by_camera_value_global, Math.floor(readLength * 1.5));
                    setTotalReadLengthPlotData(rl1);
                } else setTotalReadLengthPlotData(undefined);
            }
            else {
                setTotalReadLengthPlotData(undefined);
            }
        }
        catch {
            setTotalReadLengthPlotData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.barcode_coverage_plots, state.read_length_plots, state.by_camera_value_global, state.runId]);

    return (
        <React.Fragment>
            <Row>
                <Col style={{ flexGrow: 3, flexShrink: 1 }}>
                {
                    isDataTablesLoading ?
                        <SkeletonLoader noData={data.length === 0} isLoading={isDataTablesLoading} /> :
                        <Table striped bordered hover>
                            <tbody>
                                <tr>
                                    <th colSpan="2">Run Details</th>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Run Id</strong></td>
                                    <td>{Utils.getRunIdWithoutCamera(state.runId)}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Details</strong></td>
                                    <td>{state.current_run_data[0].details !== undefined ? state.current_run_data[0].details : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Genome</strong></td>
                                    <td>{(data.genome !== undefined && data.genome) ? data.genome : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Sequencing Start Date</strong></td>
                                    <td>{(state.current_run_data.length > 0 && state.current_run_data[0].startdatetime !== undefined) && Utils.isStructuredDate(state.current_run_data[0].startdatetime) ? state.current_run_data[0].startdatetime : Utils.restructureDate(state.current_run_data[0].startdatetime)}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Analysis Start Date</strong></td>
                                    <td>{(data.analysisStartDate && data.analysisStartDate !== "null") ? Utils.modifyFormatDate(data.analysisStartDate) : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Monitoring</strong></td>
                                    <td>{(data.reportDate !== undefined && data.reportDate)  ? Utils.modifyFormatDate(data.reportDate) : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Run time</strong></td>
                                    <td>{Utils.time_convert((state.current_run_data.length > 0 && state.current_run_data[0].runtime !== undefined && state.current_run_data[0].runtime !== null) && parseInt(state.current_run_data[0].runtime))}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Flows</strong></td>
                                    <td>{(state.current_run_data.length > 0 && state.current_run_data[0].numflows !== undefined) && state.current_run_data[0].numflows}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>BaseCalling Report Flows</strong></td>
                                    <td>{(data.baseCallingReportFlows && data.baseCallingReportFlows !== "null") ? data.baseCallingReportFlows : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Preamble flows</strong></td>
                                    <td>{(data.preambleFlows && data.preambleFlows !== "null") ? data.preambleFlows : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Barcode Region Flows</strong></td>
                                    <td>{(data.barcodeRegionFlows && data.barcodeRegionFlows !== "null") ? data.barcodeRegionFlows : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Reads in training set</strong></td>
                                    <td>{(data.readsInTrainingSet && data.readsInTrainingSet !== "null") ? data.readsInTrainingSet : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong># Reads Pass Filter (M)</strong></td>
                                    <td>{data.readsPassedFilter !== undefined && Utils.numToIntReads(data.readsPassedFilter)}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong># Output Reads (M)</strong></td>
                                    <td>{state.current_run_data.length > 0 && state.current_run_data[0].pf_output_reads !== undefined && state.current_run_data[0].pf_output_reads ? Utils.numberWithCommas(parseInt(state.current_run_data[0].pf_output_reads)) : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Flow order</strong></td>
                                    <td>{(data.flowOrder && data.flowOrder !== "null") ? data.flowOrder : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Amp Script</strong></td>
                                    <td>{(data.ampScriptVer && data.ampScriptVer !== undefined && data.ampScriptVer !== null && data.ampScriptVer !== "null") ? data.ampScriptVer : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Reagent Drawer</strong></td>
                                    <td>{(data.reagentDrawer && data.reagentDrawer !== "null") ? data.reagentDrawer : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>Sequencing Kit</strong></td>
                                    <td>{state.current_run_data[0]?.reagents !== undefined ? state.current_run_data[0].reagents : ''}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 200, textAlign: 'left' }}><strong>AMP Reagents</strong></td>
                                    <td>{state.current_run_data[0]?.amp_reagents !== undefined ? state.current_run_data[0].amp_reagents : ''}</td>
                                </tr>
                            </tbody>
                        </Table>
                    }
                </Col>
                <Col style={{ flexGrow: 3, flexShrink: 1 }}>
                    {runSummarySignalPlotsData !== undefined && firstFlowSignal !== undefined &&
                            <Row style={{ textAlign: 'center' }}>
                                <Col>
                                    <SummaryHeader label='First Flow Signal' value={`${firstFlowSignal}`} width_perc={10} />
                                </Col>
                            </Row>
                        }                        

                        {runSummarySignalPlotsData !== undefined &&
                            <Row className="text center">
                                <Col>
                                    <span>Average Signal per 4 flows (T, G, C, A) cycle</span>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col>
                                {runSummarySignalPlotsData !== undefined &&
                                    <RenderPlot id="signalPlot2" data={runSummarySignalPlotsData && runSummarySignalPlotsData.data2} layout={runSummarySignalPlotsData && runSummarySignalPlotsData.layout2} config={runSummarySignalPlotsData && runSummarySignalPlotsData.config} isLoading={state.isSignalPloatsLoading}
                                        type={'rounded'} width={'100%'} height={300} />
                                }
                            </Col>
                        </Row>
                </Col>
                <Col style={{ flexGrow: 1 }}> 
                    <Row className="text-center" style={{ textAlign: 'center' }}>

                         <Col>
                                {totalBeads !== undefined && !state.run_summary_density_plots_is_loading && runSummaryDensityPlotData !== undefined && <SummaryHeader label='Total Beads' value={`${totalBeads} M`} width_perc={20} />}
                        </Col>
                        </Row>
                            {(runSummaryDensityPlotData === undefined && dataPlots !== undefined && dataPlots.plot1 !== undefined) &&
                                <Col lg={{ span: state.is_rsq_plot_loading ? 2 : 6, offset: state.is_rsq_plot_loading ? 2 : 0 }}>
                                    {(dataPlots.plot1 && runSummaryDensityPlotData === undefined) && <Image src={dataPlots.plot1} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />}
                                </Col>
                            }
                            {runSummaryDensityPlotData !== undefined &&
                                <Col  style={state.run_summary_density_plots_is_loading ? { marginTop: 20, } : { marginTop: 5, }}>
                                    <Row>
                                        {runSummaryDensityPlotData !== undefined &&
                                            <RenderPlot id="runSummaryDensityPlot1" data={runSummaryDensityPlotData !== undefined && runSummaryDensityPlotData.data_density !== undefined && runSummaryDensityPlotData.data_density} layout={runSummaryDensityPlotData !== undefined && runSummaryDensityPlotData.layout_density && runSummaryDensityPlotData.layout_density}
                                                config={runSummaryDensityPlotData !== undefined && runSummaryDensityPlotData.config && runSummaryDensityPlotData.config} isLoading={state.run_summary_density_plots_is_loading && runSummaryDensityPlotData !== undefined}
                                                type={'circular'} width={'45%'} height={300} />}
                                    </Row>
                                </Col>
                            }
                </Col>
            </Row>
            <Row>
                <Col style={{ flexGrow: 3, flexShrink: 1 }}>
                    {
                        isDataTablesLoading ?
                            <SkeletonLoader noData={data.length === 0} isLoading={isDataTablesLoading} /> :
                            <Table striped bordered hover style={{ marginTop: 290 }}>
                                <tbody>
                                    <tr>
                                        <th colSpan="2">Software</th>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 200, textAlign: 'left' }}><strong>Photometry version</strong></td>
                                        <td>{(data.photometryVer && data.photometryVer !== undefined && data.photometryVer !== null && data.photometryVer !== "null") ? data.photometryVer : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 200, textAlign: 'left' }}><strong>SampleDb version</strong></td>
                                        <td>{(data.sampleDbV && data.sampleDbV !== undefined && data.sampleDbV !== null && data.sampleDbV !== "null") ? data.sampleDbV : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 200, textAlign: 'left' }}><strong>Sequencing Recipe Version</strong></td>
                                        <td>{(data.recipeVer && data.recipeVer !== undefined && data.recipeVer !== null && data.recipeVer !== "null") ? data.recipeVer : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 200, textAlign: 'left' }}><strong>Pegasus version</strong></td>
                                        <td>{(data.pegasusVer && data.pegasusVer !== undefined && data.pegasusVer !== null && data.pegasusVer !== "null") ? data.pegasusVer : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 200, textAlign: 'left' }}><strong>Analysis version</strong></td>
                                        <td>{(data.analysisVer && data.analysisVer !== undefined && data.analysisVer !== null && data.analysisVer !== "null") ? data.analysisVer : ''}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 200, textAlign: 'left' }}><strong>Analysis Recipe</strong></td>
                                        <td>{(data.recipe && data.recipe !== undefined && data.recipe !== null && data.recipe !== "null") ? data.recipe : (data.analysisRecipe && data.analysisRecipe !== undefined && data.analysisRecipe !== null && data.analysisRecipe !== "null" ? data.analysisRecipe : '')}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ width: 200, textAlign: 'left' }}><strong>Model</strong></td>
                                        <td>{(data.bootstrapModel && data.bootstrapModel !== undefined && data.bootstrapModel !== null && data.bootstrapModel !== "null") ? data.bootstrapModel : ''}</td>
                                    </tr>
                                </tbody>
                            </Table>
                    }
                </Col>
                <Col style={{ flexGrow: 3, flexShrink: 1 }}>
                    {totalReadLengthPlotData !== undefined && readLength !== undefined &&
                            <Row style={{ textAlign: 'center'  }}>
                                <Col>
                                    <SummaryHeader label='Total Read Length' value={`${readLength} bp`} width_perc={10} />
                                </Col>
                            </Row>
                        }                        

                        <Row>
                            <Col>
                                {totalReadLengthPlotData !== undefined &&
                                    <RenderPlot id="totalReadLengthPlot" data={totalReadLengthPlotData && totalReadLengthPlotData.data} layout={totalReadLengthPlotData && totalReadLengthPlotData.layout} config={totalReadLengthPlotData && totalReadLengthPlotData.config} isLoading={state.isSignalPloatsLoading} type={'rounded'} width={'100%'} height={300} />
                                }
                            </Col>
                        </Row>
                </Col>
                <Col style={{ flexGrow: 1}}>
                    <Row className="text-center" style={{ textAlign: 'center'  }}>
                        <Col>
                            {passFilter !== undefined && !state.is_rsq_plot_loading && rsqPlotsData !== undefined && <SummaryHeader label='Pass Filter' value={`${passFilter}%`} width_perc={20} />}
                        </Col>
                    </Row>
                    <Col className="text-center">
                            <Row>
                                {(rsqPlotsData !== undefined) && <RenderPlot id="rsq1" data={rsqPlotsData.data && rsqPlotsData.data} layout={rsqPlotsData.layout && rsqPlotsData.layout} config={rsqPlotsData.config && rsqPlotsData.config} isLoading={state.is_rsq_plot_loading} type={'circular'} width={'45%'} height={300} />}
                                {(dataPlots !== undefined && dataPlots.plot2 !== undefined && rsqPlotsData === undefined) && <Image src={dataPlots.plot2} width={isSmallScreen ? 300 : isMedium1Screen ? 400 : isMedium2Screen ? 500 : null} />}
                            </Row>
                    </Col>
                </Col>
            </Row>
            <Col lg={8}>
                <Row>
                    <Col lg={12}>

                        {dataPlots !== undefined && dataPlots.repoHashes !== undefined &&
                            <Row>
                                <Col lg={12}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                {(dataPlots !== undefined && dataPlots.repoHashes !== undefined) && dataPlots.repoHashes.map((item, i) => {
                                                    let itemSplit = item.split(',');
                                                    if (itemSplit.length >= 1) {
                                                        return <th key={Utils.generateKey(i)}>{itemSplit[0]}</th>
                                                    }
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {(dataPlots !== undefined && dataPlots.repoHashes !== undefined) && dataPlots.repoHashes.map((item, i) => {
                                                    let itemSplit = item.split(',').filter(m => m !== '');
                                                    if (itemSplit.length === 2) {
                                                        return <td key={Utils.generateKey(i)}>{itemSplit[1]}</td>;
                                                    }
                                                })}
                                            </tr>
                                            <tr>
                                                {(dataPlots !== undefined && dataPlots.repoHashes !== undefined) && dataPlots.repoHashes.map((item, i) => {
                                                    let itemSplit = item.split(',').filter(m => m !== '');
                                                    if (itemSplit.length === 3) {
                                                        return <td key={Utils.generateKey(i)}>{itemSplit[2]}</td>;
                                                    }
                                                })}
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
            </Col>
        </React.Fragment>
    )
};

export default RunConfiguration;