import React, { useEffect } from 'react';
import { Modal, Button, Tab, Tabs } from 'react-bootstrap';
import './customfilter.css';
import RunInfo from './runinfo';
import RunStatus from './runstatus';
import Signal from './signal';
import Phasing from './phasing';
import Accuracy from './accuracy';
import Library from './library';
import Analysis from './analysis';
import { setCustomFilters, setCustomFiltersApplied, setRunListDataFiltered, setNumberOfCustomFiltersApplied,clearAlert, setAlert,  } from "../actions/index";
import { useDispatch, useSelector } from "react-redux";
import * as Utils from '../../utils/utils';
import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from '@material-ui/core';

const CustomFilter = (props) => {
    const { show, handleCloseModal} = props;

    const [currentTab, setCurrentTab] = React.useState("runinfo");

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    useEffect(() => {
        try {
            let f = Utils.getColumnFilters();
            dispatch(setCustomFilters(f));
        }
        catch { }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleReset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.is_details_data_saved, state.is_analysis_comments_data_saved]);    

    const handleSaveClick = () => {
        try {
            let filters = state.custom_filters;
            let dataTofilter = state.runlist;
            let filtered = Utils.filterByCustomFilters(filters, dataTofilter, dispatch, setNumberOfCustomFiltersApplied);
            if (filtered !== undefined && filtered.length > 0) {
                dispatch(clearAlert());
                dispatch(setCustomFiltersApplied(true));
                dispatch(setRunListDataFiltered(filtered));
            }
            else {
                dispatch(setNumberOfCustomFiltersApplied(0));
                dispatch(setCustomFiltersApplied(false));
                handleReset();
                dispatch(setAlert(`No Records found`, 'Warning', 'warning'));
            }
        }
        catch { }
    }

    const handleSave = () => {
        handleSaveClick();
    }

    const handleApply = () => {
        handleSave();       
        handleCloseModal();
    }

    const handleClose = () => {
        handleCloseModal();           
    }

    const handleReset = () => {
        try {
            dispatch(setCustomFiltersApplied(false));
            let f = Utils.getColumnFilters();
            f.analysisstatus.series = f.analysisstatus.default;
            f.analysisstatus.op = 'a';
            dispatch(setCustomFilters(f));        
            dispatch(setRunListDataFiltered([]));
            dispatch(setNumberOfCustomFiltersApplied(0));
            handleCloseModal();
        }
        catch { }
    }

    const onSelectTab = (e) => {
        setCurrentTab(e);
    }

    return (
        <Modal show={show}
            onHide={handleClose}
            centered dialogClassName="nxs-modal modal-90w"
            backdrop="static" keyboard="false" >
            <Modal.Header className="border-0" style={{marginLeft: 'auto', marginRight: 0}}>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="runinfo"
                    onSelect={onSelectTab}
                    className="mb-3" fill id="custom-filters-nav" height={'50px'}>
                    <Tab eventKey="runinfo" title="Run Info">
                        <RunInfo key={`runinfo`} />
                    </Tab>
                    <Tab eventKey="runstats" title="Run Stats">
                        <RunStatus key={`runstats`} />
                    </Tab>
                    <Tab eventKey="signals" title="Signal">
                        <Signal key={`signal`} />
                    </Tab>
                    <Tab eventKey="phasing" title="Phasing">
                        <Phasing key={`phasing`} />
                    </Tab>
                    <Tab eventKey="accuracy" title="Accuracy">
                        <Accuracy key={`accuracy`} />
                    </Tab>
                    <Tab eventKey="library" title="Library Quality">
                        <Library key={`library`} />
                    </Tab>
                    <Tab eventKey="analysis" title="Analysis">
                        <Analysis key={`analysis`} />
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <div className="nxs-button mr-auto">
                    <Button onClick={handleReset} style={{boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'}}>Clear</Button>
                </div>
                <div className="nxs-button last">
                    <Button onClick={handleApply} style={{boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'}}>Apply</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default CustomFilter;